:root {
  --obs-primary: rgb(93, 92, 100);
  --obs-secondary: #dc1e35;
  --obs-maroon: #961e36;
  --obs-navy: #3e4861;
  --obs-pink: #d2afc5;
  --obs-dark-orange: #d6582a;
  --obs-gold: #9d8944;
  --obs-light-blue: #a3c2d6;
  --obs-purple: #722670;
  --obs-light-orange: #ff9c01;
  --obs-blue: #5da7d6;

  --font: "Open Sans" !important;
  --sidenav-background: #5d5c64 !important;
  --sidenav-color: white !important;
  --sidenav-toolbar-background: #5d5c64 !important;
  --sidenav-item-color: #ffffff !important;
  --sidenav-toolbar-background: #5d5c64 !important;
  --sidenav-item-icon-color: #ffffff !important;
  --sidenav-item-icon-color-active: #dc1e35 !important;
  --toolbar-icon-color: #5d5c64 !important;
  --color-secondary-50: rgb(236, 239, 255) !important;
  --color-secondary-100: rgb(206, 215, 255) !important;
  --color-secondary-200: rgb(174, 188, 255) !important;
  --color-secondary-300: rgb(142, 161, 255) !important;
  --color-secondary-400: rgb(117, 140, 255) !important;
  --color-secondary-500: rgb(92, 119, 255) !important;
  --color-secondary-600: rgb(85, 112, 255) !important;
  --color-secondary-700: rgb(75, 101, 255) !important;
  --color-secondary-800: rgb(65, 91, 255) !important;
  --color-secondary-900: rgb(48, 72, 255) !important;
  --color-secondary-A100: rgb(128, 216, 255) !important;
  --color-secondary-A200: rgb(64, 196, 255) !important;
  --color-secondary-A400: rgb(219, 223, 255) !important;
  --color-secondary-A700: rgb(194, 200, 255) !important;
}


:root vex-sidenav-item .item-label {
    font-size: 1.1em;
}
:root vex-sidenav-item .subheading {
    font-size: 1.3em;
    text-transform: none;
}
.no-pad .mat-expansion-panel-body {
    padding: 0;
}
.left {
    position: absolute;
    left: 0;
}
.right {
    position: absolute;
    right: 0;
}
.full-width {
    width: 100% !important;
}
.material-month-picker .mat-calendar-period-button {
    pointer-events: none;
}
.material-month-picker .mat-calendar-arrow {
    display: none;
}
mat-panel-title:hover p {
    color: var(--obs-blue)
}

/* Dexter add - 7/28/2020 */
.custom-button {
    padding: 5px 20px;
    margin-right: 10px;
}

.no-border {
    border: 0;
}

.error-message {
    color: #b71c1c;
    padding: 5px 0;
    font-size: 14px;
    font-style: italic;
}
.dialog-header {
    background-color: #5c5c64;
    border-radius: 10px 10px 0 0;
    font-weight: normal;
    padding: 8px 16px;
}

.dialog-header-danger {
    background-color: #b71c1c !important;
}

.dialog-title {
    color: white;
    font-size: 14pt;
}

.btn-action-dialog {
    padding: 10px 25px;
}

.dialog-body {
    padding: 48px 32px;
    font-size: 12pt;
    line-height: 1.5rem;
}

.comment-dialog-body{
    padding: 30px 30px;
    font-size: 12pt;
    line-height: 1.5rem;
}

.cancel-button {
    padding: 6px 20px;
    background-color: #E9EBEA;
    border-color: #E9EBEA;
    color: black;
    border-radius: .25rem;
}
.save-button {
    background-color: #5c5c64;
    color: white;
    padding: 5px 20px;
}
.success-button {
    background-color: #008000;
    color: white;
    padding: 5px 20px;
    border-radius: .25rem;
}
.danger-button {
    background-color: #b71c1c;
    color: white;
    padding: 5px 20px;
}
.btn:disabled {
    color:#212529;
}
.dialog-control .success-button {
    margin-left: 5px;
}
.dialog-control .cancel-button {
    margin-right: 5px;
}
.dialog-control {
    margin-top: 50px;
}

.mat-dialog-container {
    padding: 0 !important;
    border-radius: 10px !important;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.mat-expansion-panel-header-title {
    color: black !important;
}