@import"../src/assets/main.css";

@import"https://fonts.googleapis.com/icon?family=Material+Icons";

@import"~simplebar/dist/simplebar.css";

@import"~@fortawesome/fontawesome-free/css/all.min.css";

.horizontal-layout{
  --navigation-height: 0px;
  --toolbar-width: 100%
}

@media (min-width: 1280px){
  .horizontal-layout{
    --toolbar-width: calc(100% - var(--sidenav-width))
  }

  .horizontal-layout.sidenav-collapsed{
    --toolbar-width: calc(100% - var(--sidenav-collapsed-width))
  }
}

.horizontal-layout .sidenav-container{
  flex:1
}

.horizontal-layout.has-fixed-footer.scroll-disabled .content{
  height:calc(100% - var(--toolbar-height) - var(--footer-height))
}

@media (min-width: 1280px){
  body:not([dir=rtl]) .horizontal-layout.sidenav-collapsed .sidenav-content{
    margin-left:var(--sidenav-collapsed-width) !important
  }

  body:not([dir=rtl]) .horizontal-layout:not(.sidenav-collapsed) .sidenav-content{
    margin-left:var(--sidenav-width) !important
  }

  [dir=rtl] .horizontal-layout.sidenav-collapsed .sidenav-content{
    margin-right:var(--sidenav-collapsed-width) !important
  }

  [dir=rtl] .horizontal-layout:not(.sidenav-collapsed) .sidenav-content{
    margin-right:var(--sidenav-width) !important
  }
}

.vertical-layout{
  --toolbar-width: 100%
}

.vertical-layout vex-secondary-toolbar .h-14{
  display:none
}

.vertical-layout vex-secondary-toolbar .fixed{
  background:none;
  border-top:none;
  box-shadow:none;
  margin-bottom:calc(var(--padding-16)*-1);
  padding-top:var(--padding-12);
  position:relative;
  top:0
}

.vertical-layout.content-container>.sidenav-container>.sidenav-content>.content{
  margin-left:auto;
  margin-right:auto
}

.vertical-layout.has-fixed-footer.scroll-disabled .content{
  height:calc(100% - var(--toolbar-height) - var(--footer-height))
}

@media print{
  html,body{
    height:auto !important;
    overflow:initial !important
  }

  .vex-toolbar,.sidenav{
    display:none !important
  }

  .content{
    margin-top:0 !important
  }

  .mat-drawer-container{
    overflow:visible !important
  }

  .mat-drawer-side{
    border-right:none !important
  }

  .sidenav-content{
    margin-left:0 !important;
    background-color: var(--background-card)
  }

  .vex-footer{
    display:none !important
  }

  .config-panel-toggle{
    display:none !important
  }
}

.mat-icon,.mat-icon-button .mat-icon{
  font-size:var(--default-icon-size);
  height:unset;
  width:unset
}

.ic-inline>svg{
  display:inline-block
}

ic-icon:not(.ic-inline)>svg,.iconify:not(.ic-inline)>svg{
  margin:0 auto;
  vertical-align:middle
}

.vex-scrollbar{
  min-height:0
}

.vex-scrollblock{
  position:fixed;
  width:100%
}

body.is-blink ::-webkit-scrollbar{
  background-color:rgba(0,0,0,0);
  height:var(--blink-scrollbar-width);
  width:var(--blink-scrollbar-width)
}

body.is-blink ::-webkit-scrollbar:hover{
  background-color:rgba(0,0,0,.12)
}

body.is-blink ::-webkit-scrollbar-thumb{
  border:2px solid rgba(0,0,0,0);
  border-radius:var(--blink-scrollbar-width);
  box-shadow:inset 0 0 0 12px rgba(0,0,0,.37)
}

body.is-blink ::-webkit-scrollbar-thumb:active{
  border-radius:var(--blink-scrollbar-width);
  box-shadow:inset 0 0 0 12px rgba(0,0,0,.54)
}

.mat-icon-button .mat-button-wrapper>*,.mat-menu-item .mat-icon,.mat-button .mat-icon{
  vertical-align:middle !important
}

.mat-form-field-suffix .mat-icon-button .mat-icon,.mat-form-field-prefix .mat-icon-button .mat-icon{
  font-size:inherit
}

.mat-table mat-row,.mat-table mat-header-row,.mat-table mat-footer-row,.mat-table th.mat-header-cell,.mat-table td.mat-cell,.mat-table td.mat-footer-cell{
  border-bottom-color:var(--foreground-divider)
}

.mat-form-field{
  margin-bottom:4px
}

.mat-primary.mat-form-field .mat-form-field-prefix,.mat-primary.mat-form-field .mat-form-field-suffix{
  transition:var(--trans-ease-out)
}

.mat-primary.mat-form-field.mat-focused .mat-form-field-prefix,.mat-primary.mat-form-field.mat-focused .mat-form-field-suffix{
  color: #DC1E35
}

.mat-form-field.mat-form-field-invalid.mat-focused .mat-form-field-prefix,.mat-form-field.mat-form-field-invalid.mat-focused .mat-form-field-suffix{
  color: #f44336
}

.mat-table .mat-cell,.mat-table .mat-header-cell{
  box-sizing:content-box;
  padding-left:var(--padding-12);
  padding-right:var(--padding-12);
  white-space:nowrap
}

.mat-paginator-page-size-select.mat-form-field .mat-form-field-flex{
  padding-top:0
}

.mat-menu-item ic-icon{
  margin-inline-end:var(--padding-16);
  vertical-align:middle
}

.mat-menu-item ic-icon>svg{
  vertical-align:middle
}

.mat-select-panel{
  font-size: 1rem
}

textarea.mat-input-element{
  line-height: 1.5
}

.vex-flex-form-field .mat-form-field-infix{
  width:50px
}

.vex-dense-form-field{
  margin-bottom:-1.34375em
}

.vex-dense-form-field.mat-form-field-appearance-outline .mat-form-field-infix{
  padding-top:4px
}

.vex-tabs .mat-tab-label.mat-tab-label-active{
  opacity:1
}

.vex-tabs .mat-tab-link.mat-tab-label-active{
  opacity:1
}

.vex-tabs-dense .mat-tab-label{
  min-width:0
}

.uppercase>*>.mat-sort-header-button{
  text-transform: uppercase
}

.vex-style-dark{
  --background-app-bar: #11151e;
  --footer-background: var(--background-card);
  --navigation-background: #1a202e;
  --toolbar-background: #1a202e;
  --background-base: #232b3e;
  --background-card: #1a202e;
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: white;
  --toolbar-color: white;
  --text-color-light: rgba(0, 0, 0, 0.87);
  --foreground-divider: rgba(255, 255, 255, 0.12);
  --text-hint: rgba(255, 255, 255, 0.5);
  --text-hint-light: rgba(0, 0, 0, 0.38);
  --background-hover: rgba(255, 255, 255, 0.04);
  --text-secondary: rgba(255, 255, 255, 0.7);
  --text-secondary-light: rgba(0, 0, 0, 0.54);
  --secondary-toolbar-background: var(--background-card)
}

.vex-style-dark .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.vex-style-dark .mat-option{
  color:#fff
}

.vex-style-dark .mat-option:hover:not(.mat-option-disabled),.vex-style-dark .mat-option:focus:not(.mat-option-disabled){
  background:rgba(255,255,255,.04)
}

.vex-style-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled){
  background:rgba(255,255,255,.04)
}

.vex-style-dark .mat-option.mat-active{
  background:rgba(255,255,255,.04);
  color:#fff
}

.vex-style-dark .mat-option.mat-option-disabled{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
  color:#dc1e35
}

.vex-style-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled){
  color:#ff4081
}

.vex-style-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled){
  color:#f44336
}

.vex-style-dark .mat-optgroup-label{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-optgroup-disabled .mat-optgroup-label{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-pseudo-checkbox{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-pseudo-checkbox::after{
  color:#232b3e
}

.vex-style-dark .mat-pseudo-checkbox-disabled{
  color:#686868
}

.vex-style-dark .mat-primary .mat-pseudo-checkbox-checked,.vex-style-dark .mat-primary .mat-pseudo-checkbox-indeterminate{
  background:#dc1e35
}

.vex-style-dark .mat-pseudo-checkbox-checked,.vex-style-dark .mat-pseudo-checkbox-indeterminate,.vex-style-dark .mat-accent .mat-pseudo-checkbox-checked,.vex-style-dark .mat-accent .mat-pseudo-checkbox-indeterminate{
  background:#ff4081
}

.vex-style-dark .mat-warn .mat-pseudo-checkbox-checked,.vex-style-dark .mat-warn .mat-pseudo-checkbox-indeterminate{
  background:#f44336
}

.vex-style-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,.vex-style-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled{
  background:#686868
}

.vex-style-dark .mat-app-background,.vex-style-dark.mat-app-background{
  background-color:#232b3e;
  color:#fff
}

.vex-style-dark .mat-elevation-z0{
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z1{
  box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z2{
  box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z3{
  box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z4{
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z5{
  box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z6{
  box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z7{
  box-shadow:0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z8{
  box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z9{
  box-shadow:0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z10{
  box-shadow:0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z11{
  box-shadow:0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z12{
  box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z13{
  box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z14{
  box-shadow:0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z15{
  box-shadow:0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z16{
  box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z17{
  box-shadow:0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z18{
  box-shadow:0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z19{
  box-shadow:0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z20{
  box-shadow:0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z21{
  box-shadow:0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z22{
  box-shadow:0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z23{
  box-shadow:0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-elevation-z24{
  box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)
}

.mat-theme-loaded-marker{
  display:none
}

.vex-style-dark .mat-autocomplete-panel{
  background:#1a202e;
  color:#fff
}

.vex-style-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]){
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover){
  background:#1a202e
}

.vex-style-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled){
  color:#fff
}

.vex-style-dark .mat-badge{
  position:relative
}

.vex-style-dark .mat-badge.mat-badge{
  overflow:visible
}

.vex-style-dark .mat-badge-hidden .mat-badge-content{
  display:none
}

.vex-style-dark .mat-badge-content{
  position:absolute;
  text-align:center;
  display:inline-block;
  border-radius:50%;
  transition:transform 200ms ease-in-out;
  transform:scale(0.6);
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  pointer-events:none
}

.vex-style-dark .ng-animate-disabled .mat-badge-content,.vex-style-dark .mat-badge-content._mat-animation-noopable{
  transition:none
}

.vex-style-dark .mat-badge-content.mat-badge-active{
  transform:none
}

.vex-style-dark .mat-badge-small .mat-badge-content{
  width:16px;
  height:16px;
  line-height:16px
}

.vex-style-dark .mat-badge-small.mat-badge-above .mat-badge-content{
  top:-8px
}

.vex-style-dark .mat-badge-small.mat-badge-below .mat-badge-content{
  bottom:-8px
}

.vex-style-dark .mat-badge-small.mat-badge-before .mat-badge-content{
  left:-16px
}

[dir=rtl] .vex-style-dark .mat-badge-small.mat-badge-before .mat-badge-content{
  left:auto;
  right:-16px
}

.vex-style-dark .mat-badge-small.mat-badge-after .mat-badge-content{
  right:-16px
}

[dir=rtl] .vex-style-dark .mat-badge-small.mat-badge-after .mat-badge-content{
  right:auto;
  left:-16px
}

.vex-style-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:-8px
}

[dir=rtl] .vex-style-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:auto;
  right:-8px
}

.vex-style-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:-8px
}

[dir=rtl] .vex-style-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:auto;
  left:-8px
}

.vex-style-dark .mat-badge-medium .mat-badge-content{
  width:22px;
  height:22px;
  line-height:22px
}

.vex-style-dark .mat-badge-medium.mat-badge-above .mat-badge-content{
  top:-11px
}

.vex-style-dark .mat-badge-medium.mat-badge-below .mat-badge-content{
  bottom:-11px
}

.vex-style-dark .mat-badge-medium.mat-badge-before .mat-badge-content{
  left:-22px
}

[dir=rtl] .vex-style-dark .mat-badge-medium.mat-badge-before .mat-badge-content{
  left:auto;
  right:-22px
}

.vex-style-dark .mat-badge-medium.mat-badge-after .mat-badge-content{
  right:-22px
}

[dir=rtl] .vex-style-dark .mat-badge-medium.mat-badge-after .mat-badge-content{
  right:auto;
  left:-22px
}

.vex-style-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:-11px
}

[dir=rtl] .vex-style-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:auto;
  right:-11px
}

.vex-style-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:-11px
}

[dir=rtl] .vex-style-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:auto;
  left:-11px
}

.vex-style-dark .mat-badge-large .mat-badge-content{
  width:28px;
  height:28px;
  line-height:28px
}

.vex-style-dark .mat-badge-large.mat-badge-above .mat-badge-content{
  top:-14px
}

.vex-style-dark .mat-badge-large.mat-badge-below .mat-badge-content{
  bottom:-14px
}

.vex-style-dark .mat-badge-large.mat-badge-before .mat-badge-content{
  left:-28px
}

[dir=rtl] .vex-style-dark .mat-badge-large.mat-badge-before .mat-badge-content{
  left:auto;
  right:-28px
}

.vex-style-dark .mat-badge-large.mat-badge-after .mat-badge-content{
  right:-28px
}

[dir=rtl] .vex-style-dark .mat-badge-large.mat-badge-after .mat-badge-content{
  right:auto;
  left:-28px
}

.vex-style-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:-14px
}

[dir=rtl] .vex-style-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:auto;
  right:-14px
}

.vex-style-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:-14px
}

[dir=rtl] .vex-style-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:auto;
  left:-14px
}

.vex-style-dark .mat-badge-content{
  color:#fff;
  background:#dc1e35
}

.cdk-high-contrast-active .vex-style-dark .mat-badge-content{
  outline:solid 1px;
  border-radius:0
}

.vex-style-dark .mat-badge-accent .mat-badge-content{
  background:#ff4081;
  color:#fff
}

.vex-style-dark .mat-badge-warn .mat-badge-content{
  color:#fff;
  background:#f44336
}

.vex-style-dark .mat-badge-disabled .mat-badge-content{
  background:#656b78;
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-bottom-sheet-container{
  box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background:#1a202e;
  color:#fff
}

.vex-style-dark .mat-button,.vex-style-dark .mat-icon-button,.vex-style-dark .mat-stroked-button{
  color:inherit;
  background:rgba(0,0,0,0)
}

.vex-style-dark .mat-button.mat-primary,.vex-style-dark .mat-icon-button.mat-primary,.vex-style-dark .mat-stroked-button.mat-primary{
  color:#dc1e35
}

.vex-style-dark .mat-button.mat-accent,.vex-style-dark .mat-icon-button.mat-accent,.vex-style-dark .mat-stroked-button.mat-accent{
  color:#ff4081
}

.vex-style-dark .mat-button.mat-warn,.vex-style-dark .mat-icon-button.mat-warn,.vex-style-dark .mat-stroked-button.mat-warn{
  color:#f44336
}

.vex-style-dark .mat-button.mat-primary.mat-button-disabled,.vex-style-dark .mat-button.mat-accent.mat-button-disabled,.vex-style-dark .mat-button.mat-warn.mat-button-disabled,.vex-style-dark .mat-button.mat-button-disabled.mat-button-disabled,.vex-style-dark .mat-icon-button.mat-primary.mat-button-disabled,.vex-style-dark .mat-icon-button.mat-accent.mat-button-disabled,.vex-style-dark .mat-icon-button.mat-warn.mat-button-disabled,.vex-style-dark .mat-icon-button.mat-button-disabled.mat-button-disabled,.vex-style-dark .mat-stroked-button.mat-primary.mat-button-disabled,.vex-style-dark .mat-stroked-button.mat-accent.mat-button-disabled,.vex-style-dark .mat-stroked-button.mat-warn.mat-button-disabled,.vex-style-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled{
  color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-button.mat-primary .mat-button-focus-overlay,.vex-style-dark .mat-icon-button.mat-primary .mat-button-focus-overlay,.vex-style-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay{
  background-color:#dc1e35
}

.vex-style-dark .mat-button.mat-accent .mat-button-focus-overlay,.vex-style-dark .mat-icon-button.mat-accent .mat-button-focus-overlay,.vex-style-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay{
  background-color:#ff4081
}

.vex-style-dark .mat-button.mat-warn .mat-button-focus-overlay,.vex-style-dark .mat-icon-button.mat-warn .mat-button-focus-overlay,.vex-style-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay{
  background-color:#f44336
}

.vex-style-dark .mat-button.mat-button-disabled .mat-button-focus-overlay,.vex-style-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay,.vex-style-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay{
  background-color:rgba(0,0,0,0)
}

.vex-style-dark .mat-button .mat-ripple-element,.vex-style-dark .mat-icon-button .mat-ripple-element,.vex-style-dark .mat-stroked-button .mat-ripple-element{
  opacity:.1;
  background-color:currentColor
}

.vex-style-dark .mat-button-focus-overlay{
  background:#fff
}

.vex-style-dark .mat-stroked-button:not(.mat-button-disabled){
  border-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-flat-button,.vex-style-dark .mat-raised-button,.vex-style-dark .mat-fab,.vex-style-dark .mat-mini-fab{
  color:#fff;
  background-color:#424242
}

.vex-style-dark .mat-flat-button.mat-primary,.vex-style-dark .mat-raised-button.mat-primary,.vex-style-dark .mat-fab.mat-primary,.vex-style-dark .mat-mini-fab.mat-primary{
  color:#fff
}

.vex-style-dark .mat-flat-button.mat-accent,.vex-style-dark .mat-raised-button.mat-accent,.vex-style-dark .mat-fab.mat-accent,.vex-style-dark .mat-mini-fab.mat-accent{
  color:#fff
}

.vex-style-dark .mat-flat-button.mat-warn,.vex-style-dark .mat-raised-button.mat-warn,.vex-style-dark .mat-fab.mat-warn,.vex-style-dark .mat-mini-fab.mat-warn{
  color:#fff
}

.vex-style-dark .mat-flat-button.mat-primary.mat-button-disabled,.vex-style-dark .mat-flat-button.mat-accent.mat-button-disabled,.vex-style-dark .mat-flat-button.mat-warn.mat-button-disabled,.vex-style-dark .mat-flat-button.mat-button-disabled.mat-button-disabled,.vex-style-dark .mat-raised-button.mat-primary.mat-button-disabled,.vex-style-dark .mat-raised-button.mat-accent.mat-button-disabled,.vex-style-dark .mat-raised-button.mat-warn.mat-button-disabled,.vex-style-dark .mat-raised-button.mat-button-disabled.mat-button-disabled,.vex-style-dark .mat-fab.mat-primary.mat-button-disabled,.vex-style-dark .mat-fab.mat-accent.mat-button-disabled,.vex-style-dark .mat-fab.mat-warn.mat-button-disabled,.vex-style-dark .mat-fab.mat-button-disabled.mat-button-disabled,.vex-style-dark .mat-mini-fab.mat-primary.mat-button-disabled,.vex-style-dark .mat-mini-fab.mat-accent.mat-button-disabled,.vex-style-dark .mat-mini-fab.mat-warn.mat-button-disabled,.vex-style-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled{
  color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-flat-button.mat-primary,.vex-style-dark .mat-raised-button.mat-primary,.vex-style-dark .mat-fab.mat-primary,.vex-style-dark .mat-mini-fab.mat-primary{
  background-color:#dc1e35
}

.vex-style-dark .mat-flat-button.mat-accent,.vex-style-dark .mat-raised-button.mat-accent,.vex-style-dark .mat-fab.mat-accent,.vex-style-dark .mat-mini-fab.mat-accent{
  background-color:#ff4081
}

.vex-style-dark .mat-flat-button.mat-warn,.vex-style-dark .mat-raised-button.mat-warn,.vex-style-dark .mat-fab.mat-warn,.vex-style-dark .mat-mini-fab.mat-warn{
  background-color:#f44336
}

.vex-style-dark .mat-flat-button.mat-primary.mat-button-disabled,.vex-style-dark .mat-flat-button.mat-accent.mat-button-disabled,.vex-style-dark .mat-flat-button.mat-warn.mat-button-disabled,.vex-style-dark .mat-flat-button.mat-button-disabled.mat-button-disabled,.vex-style-dark .mat-raised-button.mat-primary.mat-button-disabled,.vex-style-dark .mat-raised-button.mat-accent.mat-button-disabled,.vex-style-dark .mat-raised-button.mat-warn.mat-button-disabled,.vex-style-dark .mat-raised-button.mat-button-disabled.mat-button-disabled,.vex-style-dark .mat-fab.mat-primary.mat-button-disabled,.vex-style-dark .mat-fab.mat-accent.mat-button-disabled,.vex-style-dark .mat-fab.mat-warn.mat-button-disabled,.vex-style-dark .mat-fab.mat-button-disabled.mat-button-disabled,.vex-style-dark .mat-mini-fab.mat-primary.mat-button-disabled,.vex-style-dark .mat-mini-fab.mat-accent.mat-button-disabled,.vex-style-dark .mat-mini-fab.mat-warn.mat-button-disabled,.vex-style-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled{
  background-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-flat-button.mat-primary .mat-ripple-element,.vex-style-dark .mat-raised-button.mat-primary .mat-ripple-element,.vex-style-dark .mat-fab.mat-primary .mat-ripple-element,.vex-style-dark .mat-mini-fab.mat-primary .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.vex-style-dark .mat-flat-button.mat-accent .mat-ripple-element,.vex-style-dark .mat-raised-button.mat-accent .mat-ripple-element,.vex-style-dark .mat-fab.mat-accent .mat-ripple-element,.vex-style-dark .mat-mini-fab.mat-accent .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.vex-style-dark .mat-flat-button.mat-warn .mat-ripple-element,.vex-style-dark .mat-raised-button.mat-warn .mat-ripple-element,.vex-style-dark .mat-fab.mat-warn .mat-ripple-element,.vex-style-dark .mat-mini-fab.mat-warn .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.vex-style-dark .mat-stroked-button:not([class*=mat-elevation-z]),.vex-style-dark .mat-flat-button:not([class*=mat-elevation-z]){
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-raised-button:not([class*=mat-elevation-z]){
  box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]){
  box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]){
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-fab:not([class*=mat-elevation-z]),.vex-style-dark .mat-mini-fab:not([class*=mat-elevation-z]){
  box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),.vex-style-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]){
  box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]),.vex-style-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]){
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),.vex-style-dark .mat-button-toggle-group:not([class*=mat-elevation-z]){
  box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),.vex-style-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]){
  box-shadow:none
}

.vex-style-dark .mat-button-toggle{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-button-toggle .mat-button-toggle-focus-overlay{
  background-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-button-toggle-appearance-standard{
  color:#fff;
  background:#1a202e
}

.vex-style-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay{
  background-color:#fff
}

.vex-style-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle{
  border-left:solid 1px #353b47
}

.vex-style-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle{
  border-left:none;
  border-right:solid 1px #353b47
}

.vex-style-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle+.mat-button-toggle{
  border-left:none;
  border-right:none;
  border-top:solid 1px #353b47
}

.vex-style-dark .mat-button-toggle-checked{
  background-color:#212121;
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard{
  color:#fff
}

.vex-style-dark .mat-button-toggle-disabled{
  color:rgba(255,255,255,.3);
  background-color:#000
}

.vex-style-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard{
  background:#1a202e
}

.vex-style-dark .mat-button-toggle-disabled.mat-button-toggle-checked{
  background-color:#424242
}

.vex-style-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,.vex-style-dark .mat-button-toggle-group-appearance-standard{
  border:solid 1px #353b47
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
  line-height:48px
}

.vex-style-dark .mat-card{
  background:#1a202e;
  color:#fff
}

.vex-style-dark .mat-card:not([class*=mat-elevation-z]){
  box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]){
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-card-subtitle{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-checkbox-frame{
  border-color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-checkbox-checkmark{
  fill:#232b3e
}

.vex-style-dark .mat-checkbox-checkmark-path{
  stroke:#232b3e !important
}

.vex-style-dark .mat-checkbox-mixedmark{
  background-color:#232b3e
}

.vex-style-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,.vex-style-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background{
  background-color:#dc1e35
}

.vex-style-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,.vex-style-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color:#ff4081
}

.vex-style-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,.vex-style-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background{
  background-color:#f44336
}

.vex-style-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,.vex-style-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{
  background-color:#686868
}

.vex-style-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame{
  border-color:#686868
}

.vex-style-dark .mat-checkbox-disabled .mat-checkbox-label{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-checkbox .mat-ripple-element{
  background-color:#fff
}

.vex-style-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,.vex-style-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element{
  background:#dc1e35
}

.vex-style-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,.vex-style-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{
  background:#ff4081
}

.vex-style-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,.vex-style-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element{
  background:#f44336
}

.vex-style-dark .mat-chip.mat-standard-chip{
  background-color:#616161;
  color:#fff
}

.vex-style-dark .mat-chip.mat-standard-chip .mat-chip-remove{
  color:#fff;
  opacity:.4
}

.vex-style-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active{
  box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover{
  opacity:.54
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-disabled{
  opacity:.4
}

.vex-style-dark .mat-chip.mat-standard-chip::after{
  background:#fff
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary{
  background-color:#dc1e35;
  color:#fff
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove{
  color:#fff;
  opacity:.4
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn{
  background-color:#f44336;
  color:#fff
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove{
  color:#fff;
  opacity:.4
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent{
  background-color:#ff4081;
  color:#fff
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove{
  color:#fff;
  opacity:.4
}

.vex-style-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.vex-style-dark .mat-table{
  background:#1a202e
}

.vex-style-dark .mat-table thead,.vex-style-dark .mat-table tbody,.vex-style-dark .mat-table tfoot,.vex-style-dark mat-header-row,.vex-style-dark mat-row,.vex-style-dark mat-footer-row,.vex-style-dark [mat-header-row],.vex-style-dark [mat-row],.vex-style-dark [mat-footer-row],.vex-style-dark .mat-table-sticky{
  background:inherit
}

.vex-style-dark mat-row,.vex-style-dark mat-header-row,.vex-style-dark mat-footer-row,.vex-style-dark th.mat-header-cell,.vex-style-dark td.mat-cell,.vex-style-dark td.mat-footer-cell{
  border-bottom-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-header-cell{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-cell,.vex-style-dark .mat-footer-cell{
  color:#fff
}

.vex-style-dark .mat-calendar-arrow{
  fill:#fff
}

.vex-style-dark .mat-datepicker-toggle,.vex-style-dark .mat-datepicker-content .mat-calendar-next-button,.vex-style-dark .mat-datepicker-content .mat-calendar-previous-button{
  color:#fff
}

.vex-style-dark .mat-calendar-table-header-divider::after{
  background:rgba(255,255,255,.12)
}

.vex-style-dark .mat-calendar-table-header,.vex-style-dark .mat-calendar-body-label{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-calendar-body-cell-content,.vex-style-dark .mat-date-range-input-separator{
  color:#fff;
  border-color:rgba(0,0,0,0)
}

.vex-style-dark .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-form-field-disabled .mat-date-range-input-separator{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-calendar-body-in-preview{
  color:rgba(255,255,255,.24)
}

.vex-style-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-calendar-body-in-range::before{
  background:rgba(220,30,53,.2)
}

.vex-style-dark .mat-calendar-body-comparison-identical,.vex-style-dark .mat-calendar-body-in-comparison-range::before{
  background:rgba(249,171,0,.2)
}

.vex-style-dark .mat-calendar-body-comparison-bridge-start::before,.vex-style-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
  background:linear-gradient(to right, rgba(220, 30, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.vex-style-dark .mat-calendar-body-comparison-bridge-end::before,.vex-style-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
  background:linear-gradient(to left, rgba(220, 30, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.vex-style-dark .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.vex-style-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
  background:#a8dab5
}

.vex-style-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.vex-style-dark .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
  background:#46a35e
}

.vex-style-dark .mat-calendar-body-selected{
  background-color:#dc1e35;
  color:#fff
}

.vex-style-dark .mat-calendar-body-disabled>.mat-calendar-body-selected{
  background-color:rgba(220,30,53,.4)
}

.vex-style-dark .mat-calendar-body-today.mat-calendar-body-selected{
  box-shadow:inset 0 0 0 1px #fff
}

.vex-style-dark .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.vex-style-dark .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color:rgba(220,30,53,.3)
}

@media(hover: hover){
  .vex-style-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(220,30,53,.3)
  }
}

.vex-style-dark .mat-datepicker-content{
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color:#1a202e;
  color:#fff
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before{
  background:rgba(255,64,129,.2)
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before{
  background:rgba(249,171,0,.2)
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,.vex-style-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
  background:linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,.vex-style-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
  background:linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
  background:#a8dab5
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
  background:#46a35e
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected{
  background-color:#ff4081;
  color:#fff
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled>.mat-calendar-body-selected{
  background-color:rgba(255,64,129,.4)
}

.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected{
  box-shadow:inset 0 0 0 1px #fff
}

.vex-style-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.vex-style-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color:rgba(255,64,129,.3)
}

@media(hover: hover){
  .vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(255,64,129,.3)
  }
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before{
  background:rgba(244,67,54,.2)
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before{
  background:rgba(249,171,0,.2)
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,.vex-style-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
  background:linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,.vex-style-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
  background:linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
  background:#a8dab5
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
  background:#46a35e
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected{
  background-color:#f44336;
  color:#fff
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled>.mat-calendar-body-selected{
  background-color:rgba(244,67,54,.4)
}

.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected{
  box-shadow:inset 0 0 0 1px #fff
}

.vex-style-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.vex-style-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color:rgba(244,67,54,.3)
}

@media(hover: hover){
  .vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(244,67,54,.3)
  }
}

.vex-style-dark .mat-datepicker-content-touch{
  box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-datepicker-toggle-active{
  color:#dc1e35
}

.vex-style-dark .mat-datepicker-toggle-active.mat-accent{
  color:#ff4081
}

.vex-style-dark .mat-datepicker-toggle-active.mat-warn{
  color:#f44336
}

.vex-style-dark .mat-date-range-input-inner[disabled]{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-dialog-container{
  box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background:#1a202e;
  color:#fff
}

.vex-style-dark .mat-divider{
  border-top-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-divider-vertical{
  border-right-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-expansion-panel{
  background:#1a202e;
  color:#fff
}

.vex-style-dark .mat-expansion-panel:not([class*=mat-elevation-z]){
  box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-action-row{
  border-top-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),.vex-style-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),.vex-style-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]){
  background:rgba(255,255,255,.04)
}

@media(hover: none){
  .vex-style-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover{
    background:#1a202e
  }
}

.vex-style-dark .mat-expansion-panel-header-title{
  color:#fff
}

.vex-style-dark .mat-expansion-panel-header-description,.vex-style-dark .mat-expansion-indicator::after{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-expansion-panel-header[aria-disabled=true]{
  color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,.vex-style-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description{
  color:inherit
}

.mat-expansion-panel-header{
  height:48px
}

.mat-expansion-panel-header.mat-expanded{
  height:64px
}

.vex-style-dark .mat-form-field-label{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-hint{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-form-field.mat-focused .mat-form-field-label{
  color:#dc1e35
}

.vex-style-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent{
  color:#ff4081
}

.vex-style-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn{
  color:#f44336
}

.vex-style-dark .mat-focused .mat-form-field-required-marker{
  color:#ff4081
}

.vex-style-dark .mat-form-field-ripple{
  background-color:#fff
}

.vex-style-dark .mat-form-field.mat-focused .mat-form-field-ripple{
  background-color:#dc1e35
}

.vex-style-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent{
  background-color:#ff4081
}

.vex-style-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn{
  background-color:#f44336
}

.vex-style-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after{
  color:#dc1e35
}

.vex-style-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after{
  color:#ff4081
}

.vex-style-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after{
  color:#f44336
}

.vex-style-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label{
  color:#f44336
}

.vex-style-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,.vex-style-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
  color:#f44336
}

.vex-style-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,.vex-style-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent{
  background-color:#f44336
}

.vex-style-dark .mat-error{
  color:#f44336
}

.vex-style-dark .mat-form-field-appearance-legacy .mat-form-field-label{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-form-field-appearance-legacy .mat-hint{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-form-field-appearance-legacy .mat-form-field-underline{
  background-color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{
  background-image:linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size:4px 100%;
  background-repeat:repeat-x
}

.vex-style-dark .mat-form-field-appearance-standard .mat-form-field-underline{
  background-color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline{
  background-image:linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size:4px 100%;
  background-repeat:repeat-x
}

.vex-style-dark .mat-form-field-appearance-fill .mat-form-field-flex{
  background-color:rgba(255,255,255,.1)
}

.vex-style-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex{
  background-color:rgba(255,255,255,.05)
}

.vex-style-dark .mat-form-field-appearance-fill .mat-form-field-underline::before{
  background-color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before{
  background-color:rgba(0,0,0,0)
}

.vex-style-dark .mat-form-field-appearance-outline .mat-form-field-outline{
  color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick{
  color:#fff
}

.vex-style-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
  color:#dc1e35
}

.vex-style-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick{
  color:#ff4081
}

.vex-style-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick{
  color:#f44336
}

.vex-style-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
  color:#f44336
}

.vex-style-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline{
  color:rgba(255,255,255,.15)
}

.vex-style-dark .mat-icon.mat-primary{
  color:#dc1e35
}

.vex-style-dark .mat-icon.mat-accent{
  color:#ff4081
}

.vex-style-dark .mat-icon.mat-warn{
  color:#f44336
}

.vex-style-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-input-element:disabled,.vex-style-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-input-element{
  caret-color:#dc1e35
}

.vex-style-dark .mat-input-element::placeholder{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-input-element::-moz-placeholder{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-input-element::-webkit-input-placeholder{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-input-element:-ms-input-placeholder{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-input-element:not(.mat-native-select-inline) option{
  color:rgba(0,0,0,.87)
}

.vex-style-dark .mat-input-element:not(.mat-native-select-inline) option:disabled{
  color:rgba(0,0,0,.38)
}

.vex-style-dark .mat-form-field.mat-accent .mat-input-element{
  caret-color:#ff4081
}

.vex-style-dark .mat-form-field.mat-warn .mat-input-element,.vex-style-dark .mat-form-field-invalid .mat-input-element{
  caret-color:#f44336
}

.vex-style-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after{
  color:#f44336
}

.vex-style-dark .mat-list-base .mat-list-item{
  color:#fff
}

.vex-style-dark .mat-list-base .mat-list-option{
  color:#fff
}

.vex-style-dark .mat-list-base .mat-subheader{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-list-base .mat-list-item-disabled{
  background-color:rgba(255,255,255,.12);
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-list-option:hover,.vex-style-dark .mat-list-option:focus,.vex-style-dark .mat-nav-list .mat-list-item:hover,.vex-style-dark .mat-nav-list .mat-list-item:focus,.vex-style-dark .mat-action-list .mat-list-item:hover,.vex-style-dark .mat-action-list .mat-list-item:focus{
  background:rgba(255,255,255,.04)
}

.vex-style-dark .mat-list-single-selected-option,.vex-style-dark .mat-list-single-selected-option:hover,.vex-style-dark .mat-list-single-selected-option:focus{
  background:rgba(255,255,255,.12)
}

.vex-style-dark .mat-menu-panel{
  background:#1a202e
}

.vex-style-dark .mat-menu-panel:not([class*=mat-elevation-z]){
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-menu-item{
  background:rgba(0,0,0,0);
  color:#fff
}

.vex-style-dark .mat-menu-item[disabled],.vex-style-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,.vex-style-dark .mat-menu-item[disabled] .mat-icon-no-color{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-menu-item .mat-icon-no-color,.vex-style-dark .mat-menu-submenu-icon{
  color:#fff
}

.vex-style-dark .mat-menu-item:hover:not([disabled]),.vex-style-dark .mat-menu-item.cdk-program-focused:not([disabled]),.vex-style-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),.vex-style-dark .mat-menu-item-highlighted:not([disabled]){
  background:rgba(255,255,255,.04)
}

.vex-style-dark .mat-paginator{
  background:#1a202e
}

.vex-style-dark .mat-paginator,.vex-style-dark .mat-paginator-page-size .mat-select-trigger{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-paginator-decrement,.vex-style-dark .mat-paginator-increment{
  border-top:2px solid #fff;
  border-right:2px solid #fff
}

.vex-style-dark .mat-paginator-first,.vex-style-dark .mat-paginator-last{
  border-top:2px solid #fff
}

.vex-style-dark .mat-icon-button[disabled] .mat-paginator-decrement,.vex-style-dark .mat-icon-button[disabled] .mat-paginator-increment,.vex-style-dark .mat-icon-button[disabled] .mat-paginator-first,.vex-style-dark .mat-icon-button[disabled] .mat-paginator-last{
  border-color:rgba(255,255,255,.5)
}

.mat-paginator-container{
  min-height:56px
}

.vex-style-dark .mat-progress-bar-background{
  fill:#51283c
}

.vex-style-dark .mat-progress-bar-buffer{
  background-color:#51283c
}

.vex-style-dark .mat-progress-bar-fill::after{
  background-color:#dc1e35
}

.vex-style-dark .mat-progress-bar.mat-accent .mat-progress-bar-background{
  fill:#5a304f
}

.vex-style-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer{
  background-color:#5a304f
}

.vex-style-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after{
  background-color:#ff4081
}

.vex-style-dark .mat-progress-bar.mat-warn .mat-progress-bar-background{
  fill:#57313c
}

.vex-style-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer{
  background-color:#57313c
}

.vex-style-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after{
  background-color:#f44336
}

.vex-style-dark .mat-progress-spinner circle,.vex-style-dark .mat-spinner circle{
  stroke:#dc1e35
}

.vex-style-dark .mat-progress-spinner.mat-accent circle,.vex-style-dark .mat-spinner.mat-accent circle{
  stroke:#ff4081
}

.vex-style-dark .mat-progress-spinner.mat-warn circle,.vex-style-dark .mat-spinner.mat-warn circle{
  stroke:#f44336
}

.vex-style-dark .mat-radio-outer-circle{
  border-color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle{
  border-color:#dc1e35
}

.vex-style-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,.vex-style-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.vex-style-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,.vex-style-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple{
  background-color:#dc1e35
}

.vex-style-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:#ff4081
}

.vex-style-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,.vex-style-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.vex-style-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.vex-style-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
  background-color:#ff4081
}

.vex-style-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle{
  border-color:#f44336
}

.vex-style-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,.vex-style-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.vex-style-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,.vex-style-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple{
  background-color:#f44336
}

.vex-style-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,.vex-style-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle{
  border-color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,.vex-style-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle{
  background-color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-radio-button .mat-ripple-element{
  background-color:#fff
}

.vex-style-dark .mat-select-value{
  color:#fff
}

.vex-style-dark .mat-select-placeholder{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-select-disabled .mat-select-value{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-select-arrow{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-select-panel{
  background:#1a202e
}

.vex-style-dark .mat-select-panel:not([class*=mat-elevation-z]){
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple){
  background:rgba(255,255,255,.12)
}

.vex-style-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow{
  color:#dc1e35
}

.vex-style-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow{
  color:#ff4081
}

.vex-style-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow{
  color:#f44336
}

.vex-style-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow{
  color:#f44336
}

.vex-style-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-drawer-container{
  background-color:#232b3e;
  color:#fff
}

.vex-style-dark .mat-drawer{
  background-color:#1a202e;
  color:#fff
}

.vex-style-dark .mat-drawer.mat-drawer-push{
  background-color:#1a202e
}

.vex-style-dark .mat-drawer:not(.mat-drawer-side){
  box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-drawer-side{
  border-right:solid 1px rgba(255,255,255,.12)
}

.vex-style-dark .mat-drawer-side.mat-drawer-end{
  border-left:solid 1px rgba(255,255,255,.12);
  border-right:none
}

.vex-style-dark [dir=rtl] .mat-drawer-side{
  border-left:solid 1px rgba(255,255,255,.12);
  border-right:none
}

.vex-style-dark [dir=rtl] .mat-drawer-side.mat-drawer-end{
  border-left:none;
  border-right:solid 1px rgba(255,255,255,.12)
}

.vex-style-dark .mat-drawer-backdrop.mat-drawer-shown{
  background-color:rgba(229,223,209,.6)
}

.vex-style-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
  background-color:#ff4081
}

.vex-style-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
  background-color:rgba(255,64,129,.54)
}

.vex-style-dark .mat-slide-toggle.mat-checked .mat-ripple-element{
  background-color:#ff4081
}

.vex-style-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb{
  background-color:#dc1e35
}

.vex-style-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar{
  background-color:rgba(220,30,53,.54)
}

.vex-style-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element{
  background-color:#dc1e35
}

.vex-style-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb{
  background-color:#f44336
}

.vex-style-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar{
  background-color:rgba(244,67,54,.54)
}

.vex-style-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element{
  background-color:#f44336
}

.vex-style-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element{
  background-color:#fff
}

.vex-style-dark .mat-slide-toggle-thumb{
  box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color:#bdbdbd
}

.vex-style-dark .mat-slide-toggle-bar{
  background-color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-slider-track-background{
  background-color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-slider.mat-primary .mat-slider-track-fill,.vex-style-dark .mat-slider.mat-primary .mat-slider-thumb,.vex-style-dark .mat-slider.mat-primary .mat-slider-thumb-label{
  background-color:#dc1e35
}

.vex-style-dark .mat-slider.mat-primary .mat-slider-thumb-label-text{
  color:#fff
}

.vex-style-dark .mat-slider.mat-primary .mat-slider-focus-ring{
  background-color:rgba(220,30,53,.2)
}

.vex-style-dark .mat-slider.mat-accent .mat-slider-track-fill,.vex-style-dark .mat-slider.mat-accent .mat-slider-thumb,.vex-style-dark .mat-slider.mat-accent .mat-slider-thumb-label{
  background-color:#ff4081
}

.vex-style-dark .mat-slider.mat-accent .mat-slider-thumb-label-text{
  color:#fff
}

.vex-style-dark .mat-slider.mat-accent .mat-slider-focus-ring{
  background-color:rgba(255,64,129,.2)
}

.vex-style-dark .mat-slider.mat-warn .mat-slider-track-fill,.vex-style-dark .mat-slider.mat-warn .mat-slider-thumb,.vex-style-dark .mat-slider.mat-warn .mat-slider-thumb-label{
  background-color:#f44336
}

.vex-style-dark .mat-slider.mat-warn .mat-slider-thumb-label-text{
  color:#fff
}

.vex-style-dark .mat-slider.mat-warn .mat-slider-focus-ring{
  background-color:rgba(244,67,54,.2)
}

.vex-style-dark .mat-slider:hover .mat-slider-track-background,.vex-style-dark .mat-slider.cdk-focused .mat-slider-track-background{
  background-color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,.vex-style-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,.vex-style-dark .mat-slider.mat-slider-disabled .mat-slider-thumb{
  background-color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background{
  background-color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring{
  background-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,.vex-style-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label{
  background-color:#fff
}

.vex-style-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,.vex-style-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label{
  background-color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb{
  border-color:rgba(255,255,255,.3);
  background-color:rgba(0,0,0,0)
}

.vex-style-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb,.vex-style-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb{
  border-color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb,.vex-style-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb{
  border-color:rgba(255,255,255,.3)
}

.vex-style-dark .mat-slider-has-ticks .mat-slider-wrapper::after{
  border-color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-slider-horizontal .mat-slider-ticks{
  background-image:repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image:-moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent)
}

.vex-style-dark .mat-slider-vertical .mat-slider-ticks{
  background-image:repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent)
}

.vex-style-dark .mat-step-header.cdk-keyboard-focused,.vex-style-dark .mat-step-header.cdk-program-focused,.vex-style-dark .mat-step-header:hover:not([aria-disabled]),.vex-style-dark .mat-step-header:hover[aria-disabled=false]{
  background-color:rgba(255,255,255,.04)
}

.vex-style-dark .mat-step-header:hover[aria-disabled=true]{
  cursor:default
}

@media(hover: none){
  .vex-style-dark .mat-step-header:hover{
    background:none
  }
}

.vex-style-dark .mat-step-header .mat-step-label,.vex-style-dark .mat-step-header .mat-step-optional{
  color:rgba(255,255,255,.7)
}

.vex-style-dark .mat-step-header .mat-step-icon{
  background-color:rgba(255,255,255,.7);
  color:#fff
}

.vex-style-dark .mat-step-header .mat-step-icon-selected,.vex-style-dark .mat-step-header .mat-step-icon-state-done,.vex-style-dark .mat-step-header .mat-step-icon-state-edit{
  background-color:#dc1e35;
  color:#fff
}

.vex-style-dark .mat-step-header.mat-accent .mat-step-icon{
  color:#fff
}

.vex-style-dark .mat-step-header.mat-accent .mat-step-icon-selected,.vex-style-dark .mat-step-header.mat-accent .mat-step-icon-state-done,.vex-style-dark .mat-step-header.mat-accent .mat-step-icon-state-edit{
  background-color:#ff4081;
  color:#fff
}

.vex-style-dark .mat-step-header.mat-warn .mat-step-icon{
  color:#fff
}

.vex-style-dark .mat-step-header.mat-warn .mat-step-icon-selected,.vex-style-dark .mat-step-header.mat-warn .mat-step-icon-state-done,.vex-style-dark .mat-step-header.mat-warn .mat-step-icon-state-edit{
  background-color:#f44336;
  color:#fff
}

.vex-style-dark .mat-step-header .mat-step-icon-state-error{
  background-color:rgba(0,0,0,0);
  color:#f44336
}

.vex-style-dark .mat-step-header .mat-step-label.mat-step-label-active{
  color:#fff
}

.vex-style-dark .mat-step-header .mat-step-label.mat-step-label-error{
  color:#f44336
}

.vex-style-dark .mat-stepper-horizontal,.vex-style-dark .mat-stepper-vertical{
  background-color:#1a202e
}

.vex-style-dark .mat-stepper-vertical-line::before{
  border-left-color:rgba(255,255,255,.12)
}

.vex-style-dark .mat-horizontal-stepper-header::before,.vex-style-dark .mat-horizontal-stepper-header::after,.vex-style-dark .mat-stepper-horizontal-line{
  border-top-color:rgba(255,255,255,.12)
}

.mat-horizontal-stepper-header{
  height:72px
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,.mat-vertical-stepper-header{
  padding:24px 24px
}

.mat-stepper-vertical-line::before{
  top:-16px;
  bottom:-16px
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before{
  top:36px
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line{
  top:36px
}

.vex-style-dark .mat-sort-header-arrow{
  color:#babcc0
}

.vex-style-dark .mat-tab-nav-bar,.vex-style-dark .mat-tab-header{
  border-bottom:1px solid rgba(255,255,255,.12)
}

.vex-style-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,.vex-style-dark .mat-tab-group-inverted-header .mat-tab-header{
  border-top:1px solid rgba(255,255,255,.12);
  border-bottom:none
}

.vex-style-dark .mat-tab-label,.vex-style-dark .mat-tab-link{
  color:#fff
}

.vex-style-dark .mat-tab-label.mat-tab-disabled,.vex-style-dark .mat-tab-link.mat-tab-disabled{
  color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-tab-header-pagination-chevron{
  border-color:#fff
}

.vex-style-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
  border-color:rgba(255,255,255,.5)
}

.vex-style-dark .mat-tab-group[class*=mat-background-]>.mat-tab-header,.vex-style-dark .mat-tab-nav-bar[class*=mat-background-]{
  border-bottom:none;
  border-top:none
}

.vex-style-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(245,188,194,.3)
}

.vex-style-dark .mat-tab-group.mat-primary .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background-color:#dc1e35
}

.vex-style-dark .mat-tab-group.mat-primary.mat-background-primary>.mat-tab-header .mat-ink-bar,.vex-style-dark .mat-tab-group.mat-primary.mat-background-primary>.mat-tab-link-container .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-primary.mat-background-primary>.mat-tab-header .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-primary.mat-background-primary>.mat-tab-link-container .mat-ink-bar{
  background-color:#fff
}

.vex-style-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(255,128,171,.3)
}

.vex-style-dark .mat-tab-group.mat-accent .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar{
  background-color:#ff4081
}

.vex-style-dark .mat-tab-group.mat-accent.mat-background-accent>.mat-tab-header .mat-ink-bar,.vex-style-dark .mat-tab-group.mat-accent.mat-background-accent>.mat-tab-link-container .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-accent.mat-background-accent>.mat-tab-header .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-accent.mat-background-accent>.mat-tab-link-container .mat-ink-bar{
  background-color:#fff
}

.vex-style-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(255,205,210,.3)
}

.vex-style-dark .mat-tab-group.mat-warn .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar{
  background-color:#f44336
}

.vex-style-dark .mat-tab-group.mat-warn.mat-background-warn>.mat-tab-header .mat-ink-bar,.vex-style-dark .mat-tab-group.mat-warn.mat-background-warn>.mat-tab-link-container .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-warn.mat-background-warn>.mat-tab-header .mat-ink-bar,.vex-style-dark .mat-tab-nav-bar.mat-warn.mat-background-warn>.mat-tab-link-container .mat-ink-bar{
  background-color:#fff
}

.vex-style-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(245,188,194,.3)
}

.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-link-container,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header-pagination,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination{
  background-color:#dc1e35
}

.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-label,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-tab-link,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-label,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-tab-link{
  color:#fff
}

.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-label.mat-tab-disabled,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-label.mat-tab-disabled,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
  color:rgba(255,255,255,.4)
}

.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-focus-indicator::before,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header .mat-focus-indicator::before,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-focus-indicator::before,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-focus-indicator::before{
  border-color:#fff
}

.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
  border-color:#fff;
  opacity:.4
}

.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header .mat-ripple-element,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-ripple-element,.vex-style-dark .mat-tab-group.mat-background-primary>.mat-tab-header-pagination .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination .mat-ripple-element{
  background-color:#fff;
  opacity:.12
}

.vex-style-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(255,128,171,.3)
}

.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-link-container,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header-pagination,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination{
  background-color:#ff4081
}

.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-label,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-tab-link,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-label,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-tab-link{
  color:#fff
}

.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-label.mat-tab-disabled,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-label.mat-tab-disabled,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
  color:rgba(255,255,255,.4)
}

.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-focus-indicator::before,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header .mat-focus-indicator::before,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-focus-indicator::before,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-focus-indicator::before{
  border-color:#fff
}

.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
  border-color:#fff;
  opacity:.4
}

.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header .mat-ripple-element,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-ripple-element,.vex-style-dark .mat-tab-group.mat-background-accent>.mat-tab-header-pagination .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination .mat-ripple-element{
  background-color:#fff;
  opacity:.12
}

.vex-style-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.vex-style-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(255,205,210,.3)
}

.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-link-container,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header-pagination,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination{
  background-color:#f44336
}

.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-label,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-tab-link,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-label,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-tab-link{
  color:#fff
}

.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-label.mat-tab-disabled,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-label.mat-tab-disabled,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
  color:rgba(255,255,255,.4)
}

.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-focus-indicator::before,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header .mat-focus-indicator::before,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-focus-indicator::before,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-focus-indicator::before{
  border-color:#fff
}

.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
  border-color:#fff;
  opacity:.4
}

.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header .mat-ripple-element,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-ripple-element,.vex-style-dark .mat-tab-group.mat-background-warn>.mat-tab-header-pagination .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-ripple-element,.vex-style-dark .mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination .mat-ripple-element{
  background-color:#fff;
  opacity:.12
}

.vex-style-dark .mat-toolbar{
  background:#11151e;
  color:#fff
}

.vex-style-dark .mat-toolbar.mat-primary{
  background:#dc1e35;
  color:#fff
}

.vex-style-dark .mat-toolbar.mat-accent{
  background:#ff4081;
  color:#fff
}

.vex-style-dark .mat-toolbar.mat-warn{
  background:#f44336;
  color:#fff
}

.vex-style-dark .mat-toolbar .mat-form-field-underline,.vex-style-dark .mat-toolbar .mat-form-field-ripple,.vex-style-dark .mat-toolbar .mat-focused .mat-form-field-ripple{
  background-color:currentColor
}

.vex-style-dark .mat-toolbar .mat-form-field-label,.vex-style-dark .mat-toolbar .mat-focused .mat-form-field-label,.vex-style-dark .mat-toolbar .mat-select-value,.vex-style-dark .mat-toolbar .mat-select-arrow,.vex-style-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow{
  color:inherit
}

.vex-style-dark .mat-toolbar .mat-input-element{
  caret-color:currentColor
}

.mat-toolbar-multiple-rows{
  min-height:64px
}

.mat-toolbar-row,.mat-toolbar-single-row{
  height:64px
}

@media(max-width: 599px){
  .mat-toolbar-multiple-rows{
    min-height:56px
  }

  .mat-toolbar-row,.mat-toolbar-single-row{
    height:56px
  }
}

.vex-style-dark .mat-tooltip{
  background:rgba(97,97,97,.9)
}

.vex-style-dark .mat-tree{
  background:#1a202e
}

.vex-style-dark .mat-tree-node,.vex-style-dark .mat-nested-tree-node{
  color:#fff
}

.mat-tree-node{
  min-height:48px
}

.vex-style-dark .mat-snack-bar-container{
  color:rgba(0,0,0,.87);
  background:#fafafa;
  box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.vex-style-dark .mat-simple-snackbar-action{
  color:inherit
}

.vex-style-light{
  --navigation-background: var(--background-card);
  --sidenav-background: white;
  --toolbar-background: var(--background-card);
  --sidenav-color: var(--text-color);
  --sidenav-item-background-active: #fafafa;
  --sidenav-item-color: var(--text-color);
  --sidenav-item-color-active: var(--text-color);
  --sidenav-item-dropdown-background: #fafafa;
  --sidenav-item-dropdown-background-hover: #DC1E35;
  --sidenav-item-icon-color: #494B74;
  --sidenav-item-icon-color-active: #DC1E35;
  --sidenav-toolbar-background: white
}

:root{
  --padding-gutter: 1rem;
  --padding: 1.5rem;
  --padding-16: 1rem;
  --padding-12: 0.75rem;
  --padding-8: 0.5rem;
  --padding-4: 0.25rem;
  --font: Roboto, "Helvetica Neue", sans-serif;
  --font-weight-medium: 500;
  --font-caption: 400 12px/20px var(--font);
  --font-body-1: 400 14px/20px var(--font);
  --font-body-2: 500 14px/24px var(--font);
  --font-subheading-1: 400 15px/24px var(--font);
  --font-subheading-2: 400 16px/28px var(--font);
  --font-headline: 400 24px/32px var(--font);
  --font-title: 500 18px/26px var(--font);
  --font-display-1: 400 34px/40px var(--font);
  --font-display-2: 400 45px/48px var(--font);
  --font-display-3: 400 56px/56px var(--font);
  --font-display-4: 300 112px/112px var(--font);
  --trans-ease-in-out: all var(--trans-ease-in-out-duration) var(--trans-ease-in-out-timing-function);
  --trans-ease-in-out-duration: 500ms;
  --trans-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  --trans-ease-out: all var(--trans-ease-out-duration) var(--trans-ease-out-timing-function);
  --trans-ease-out-duration: 400ms;
  --trans-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  --trans-ease-in: all var(--trans-ease-in-duration) var(--trans-ease-in-timing-function);
  --trans-ease-in-duration: 300ms;
  --trans-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  --trans-shadow-duration: 280ms;
  --trans-shadow-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --trans-shadow: box-shadow var(--trans-shadow-duration) var(--trans-shadow-timing-function);
  --text-color: rgba(0, 0, 0, 0.87);
  --text-color-light: white;
  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-secondary-light: rgba(255, 255, 255, 0.7);
  --text-hint: rgba(0, 0, 0, 0.38);
  --text-hint-light: rgba(255, 255, 255, 0.5);
  --foreground-divider: rgba(82, 63, 105, 0.06);
  --background-base: rgb(245, 245, 248);
  --background-card: white;
  --background-app-bar: #ebebee;
  --background-hover: rgba(0, 0, 0, 0.04);
  --elevation-default: var(--elevation-z6);
  --elevation-z0: none;
  --elevation-z1: 0px 2px 1px -1px rgba(82, 63, 104, 0.06), 0px 1px 1px 0px rgba(82, 63, 104, 0.042), 0px 1px 3px 0px rgba(82, 63, 104, 0.036);
  --elevation-z2: 0px 3px 1px -2px rgba(82, 63, 104, 0.06), 0px 2px 2px 0px rgba(82, 63, 104, 0.042), 0px 1px 5px 0px rgba(82, 63, 104, 0.036);
  --elevation-z3: 0px 3px 3px -2px rgba(82, 63, 104, 0.06), 0px 3px 4px 0px rgba(82, 63, 104, 0.042), 0px 1px 8px 0px rgba(82, 63, 104, 0.036);
  --elevation-z4: 0px 2px 4px -1px rgba(82, 63, 104, 0.06), 0px 4px 5px 0px rgba(82, 63, 104, 0.042), 0px 1px 10px 0px rgba(82, 63, 104, 0.036);
  --elevation-z5: 0px 3px 5px -1px rgba(82, 63, 104, 0.06), 0px 5px 8px 0px rgba(82, 63, 104, 0.042), 0px 1px 14px 0px rgba(82, 63, 104, 0.036);
  --elevation-z6: 0px 3px 5px -1px rgba(82, 63, 104, 0.06), 0px 6px 10px 0px rgba(82, 63, 104, 0.042), 0px 1px 18px 0px rgba(82, 63, 104, 0.036);
  --elevation-z7: 0px 4px 5px -2px rgba(82, 63, 104, 0.06), 0px 7px 10px 1px rgba(82, 63, 104, 0.042), 0px 2px 16px 1px rgba(82, 63, 104, 0.036);
  --elevation-z8: 0px 5px 5px -3px rgba(82, 63, 104, 0.06), 0px 8px 10px 1px rgba(82, 63, 104, 0.042), 0px 3px 14px 2px rgba(82, 63, 104, 0.036);
  --elevation-z9: 0px 5px 6px -3px rgba(82, 63, 104, 0.06), 0px 9px 12px 1px rgba(82, 63, 104, 0.042), 0px 3px 16px 2px rgba(82, 63, 104, 0.036);
  --elevation-z10: 0px 6px 6px -3px rgba(82, 63, 104, 0.06), 0px 10px 14px 1px rgba(82, 63, 104, 0.042), 0px 4px 18px 3px rgba(82, 63, 104, 0.036);
  --elevation-z11: 0px 6px 7px -4px rgba(82, 63, 104, 0.06), 0px 11px 15px 1px rgba(82, 63, 104, 0.042), 0px 4px 20px 3px rgba(82, 63, 104, 0.036);
  --elevation-z12: 0px 7px 8px -4px rgba(82, 63, 104, 0.06), 0px 12px 17px 2px rgba(82, 63, 104, 0.042), 0px 5px 22px 4px rgba(82, 63, 104, 0.036);
  --elevation-z13: 0px 7px 8px -4px rgba(82, 63, 104, 0.06), 0px 13px 19px 2px rgba(82, 63, 104, 0.042), 0px 5px 24px 4px rgba(82, 63, 104, 0.036);
  --elevation-z14: 0px 7px 9px -4px rgba(82, 63, 104, 0.06), 0px 14px 21px 2px rgba(82, 63, 104, 0.042), 0px 5px 26px 4px rgba(82, 63, 104, 0.036);
  --elevation-z15: 0px 8px 9px -5px rgba(82, 63, 104, 0.06), 0px 15px 22px 2px rgba(82, 63, 104, 0.042), 0px 6px 28px 5px rgba(82, 63, 104, 0.036);
  --elevation-z16: 0px 8px 10px -5px rgba(82, 63, 104, 0.06), 0px 16px 24px 2px rgba(82, 63, 104, 0.042), 0px 6px 30px 5px rgba(82, 63, 104, 0.036);
  --elevation-z17: 0px 8px 11px -5px rgba(82, 63, 104, 0.06), 0px 17px 26px 2px rgba(82, 63, 104, 0.042), 0px 6px 32px 5px rgba(82, 63, 104, 0.036);
  --elevation-z18: 0px 9px 11px -5px rgba(82, 63, 104, 0.06), 0px 18px 28px 2px rgba(82, 63, 104, 0.042), 0px 7px 34px 6px rgba(82, 63, 104, 0.036);
  --elevation-z19: 0px 9px 12px -6px rgba(82, 63, 104, 0.06), 0px 19px 29px 2px rgba(82, 63, 104, 0.042), 0px 7px 36px 6px rgba(82, 63, 104, 0.036);
  --elevation-z20: 0px 10px 13px -6px rgba(82, 63, 104, 0.06), 0px 20px 31px 3px rgba(82, 63, 104, 0.042), 0px 8px 38px 7px rgba(82, 63, 104, 0.036);
  --sidenav-width: 280px;
  --sidenav-collapsed-width: 72px;
  --sidenav-background: #1a202e;
  --sidenav-color: white;
  --sidenav-item-padding: var(--padding);
  --sidenav-toolbar-background: #171d29;
  --sidenav-item-background-active: #141924;
  --sidenav-item-color: #A1A2B6;
  --sidenav-item-color-active: white;
  --sidenav-item-icon-color: #494B74;
  --sidenav-item-icon-color-active: #DC1E35;
  --sidenav-item-icon-gap: 16px;
  --sidenav-item-icon-size: 24px;
  --sidenav-item-dropdown-background: #141924;
  --sidenav-item-dropdown-background-hover: #DC1E35;
  --sidenav-item-dropdown-gap: 12px;
  --toolbar-height: 64px;
  --toolbar-background: white;
  --toolbar-color: rgba(0, 0, 0, 0.87);
  --toolbar-icon-color: #DC1E35;
  --secondary-toolbar-background: var(--background-card);
  --secondary-toolbar-height: 54px;
  --navigation-height: 64px;
  --navigation-background: var(--background-card);
  --navigation-color: var(--text-secondary);
  --footer-height: 56px;
  --footer-z-index: 100;
  --footer-background: var(--background-card);
  --footer-color: var(--text-color);
  --footer-elevation: 0 -10px 30px 0 rgba(82, 63, 104, .06);
  --page-layout-header-height: 200px;
  --page-layout-toolbar-height: 64px;
  --blink-scrollbar-width: 12px;
  --default-icon-size: 24px;
  --border-radius: 4px
}

@media (min-width: 1280px){
  :root{
    --padding-gutter: 1.5rem
  }
}

.vex-layout-ares{
  --footer-background: var(--background-base);
  --sidenav-background: var(--background-base);
  --toolbar-background: var(--background-base);
  --sidenav-color: var(--text-color);
  --sidenav-item-background-active: var(--background-card);
  --sidenav-item-color: var(--text-color);
  --sidenav-item-color-active: var(--text-color);
  --sidenav-item-dropdown-background: var(--background-base);
  --sidenav-item-dropdown-background-hover: var(--background-hover);
  --secondary-toolbar-background: var(--background-base);
  --sidenav-toolbar-background: var(--background-base);
  --secondary-toolbar-height: 64px
}

.vex-layout-ares .sidenav{
  border:none
}

.vex-layout-ares .sidenav vex-sidenav-item .item{
  width:unset;
  border-radius: 9999px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem
}

.vex-layout-ares .sidenav vex-sidenav-item .item.active{
  box-shadow: var(--elevation-z6)
}

.vex-layout-ares .sidenav vex-sidenav-item .item-level-0>.item{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.vex-layout-ares .sidenav vex-sidenav-item .item-level-1 .item.active{
  box-shadow: none;
  font-weight: 500
}

.vex-layout-ares .sidenav vex-sidenav-item .item-level-1 .item.active .item-label{
  color: var(--text-color)
}

.vex-layout-ares .sidenav vex-sidenav-item .item-level-1 .item:hover .item-label{
  color: var(--text-color)
}

.vex-layout-ares .sidenav-collapsed .sidenav vex-sidenav-item .item{
  margin-left: 0;
  margin-right: 0
}

.vex-layout-ares vex-secondary-toolbar{
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  overflow: hidden
}

.vex-layout-ares vex-secondary-toolbar .secondary-toolbar{
  box-shadow: none;
  border-top-width: 0;
  margin-bottom: calc(var(--padding-gutter) * -1)
}

.vex-layout-ares vex-footer{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden
}

.vex-layout-ares .vex-page-layout-header{
  background:var(--background-base);
  color:var(--text-color)
}

.vex-layout-ares .vex-page-layout-header vex-breadcrumbs .text-hint{
  color:var(--text-hint) !important
}

.vex-layout-ares.vex-style-default{
  --sidenav-item-background-active: #DC1E35;
  --sidenav-item-color-active: #FFF;
  --sidenav-item-dropdown-color-hover: var(--text-color);
  --sidenav-item-icon-color-active: #FFF
}

.vex-layout-hermes{
  --toolbar-height: 100px;
  --secondary-toolbar-background: var(--background-base);
  --secondary-toolbar-height: 64px
}

.vex-layout-hermes.vex-style-light{
  --toolbar-background: var(--background-base)
}

.vex-layout-hermes.vex-style-default{
  --navigation-background: #1a202e;
  --toolbar-background: #232b3e;
  --navigation-color: white;
  --toolbar-color: white
}

.vex-layout-hermes.vex-style-dark{
  --toolbar-background: var(--background-base)
}

.vex-layout-hermes vex-secondary-toolbar .secondary-toolbar{
  box-shadow: none;
  margin-bottom: calc(var(--padding-gutter) * -1);
  border-top-width: 0
}

.vex-layout-ikaros{
  --navigation-height: 0px
}

.vex-layout-ikaros.vex-style-default{
  --toolbar-background: #1a202e;
  --navigation-color: white;
  --toolbar-color: white
}

.mat-badge-content{
  font-weight:600;
  font-size:12px;
  font-family:var(--font)
}

.mat-badge-small .mat-badge-content{
  font-size:9px
}

.mat-badge-large .mat-badge-content{
  font-size:24px
}

.mat-h1,.mat-headline,.mat-typography .mat-h1,.mat-typography .mat-headline,.mat-typography h1{
  font:400 24px/32px var(--font);
  letter-spacing:normal;
  margin:0 0 16px
}

.mat-h2,.mat-title,.mat-typography .mat-h2,.mat-typography .mat-title,.mat-typography h2{
  font:500 18px/26px var(--font);
  letter-spacing:normal;
  margin:0 0 16px
}

.mat-h3,.mat-subheading-2,.mat-typography .mat-h3,.mat-typography .mat-subheading-2,.mat-typography h3{
  font:400 16px/28px var(--font);
  letter-spacing:normal;
  margin:0 0 16px
}

.mat-h4,.mat-subheading-1,.mat-typography .mat-h4,.mat-typography .mat-subheading-1,.mat-typography h4{
  font:400 15px/24px var(--font);
  letter-spacing:normal;
  margin:0 0 16px
}

.mat-h5,.mat-typography .mat-h5,.mat-typography h5{
  font:400 calc(14px * 0.83)/20px var(--font);
  margin:0 0 12px
}

.mat-h6,.mat-typography .mat-h6,.mat-typography h6{
  font:400 calc(14px * 0.67)/20px var(--font);
  margin:0 0 12px
}

.mat-body-strong,.mat-body-2,.mat-typography .mat-body-strong,.mat-typography .mat-body-2{
  font:500 14px/24px var(--font);
  letter-spacing:normal
}

.mat-body,.mat-body-1,.mat-typography .mat-body,.mat-typography .mat-body-1,.mat-typography{
  font:400 14px/20px var(--font);
  letter-spacing:normal
}

.mat-body p,.mat-body-1 p,.mat-typography .mat-body p,.mat-typography .mat-body-1 p,.mat-typography p{
  margin:0 0 12px
}

.mat-small,.mat-caption,.mat-typography .mat-small,.mat-typography .mat-caption{
  font:400 12px/20px var(--font);
  letter-spacing:normal
}

.mat-display-4,.mat-typography .mat-display-4{
  font:300 112px/112px var(--font);
  letter-spacing:-0.05em;
  margin:0 0 56px
}

.mat-display-3,.mat-typography .mat-display-3{
  font:400 56px/56px var(--font);
  letter-spacing:-0.02em;
  margin:0 0 64px
}

.mat-display-2,.mat-typography .mat-display-2{
  font:400 45px/48px var(--font);
  letter-spacing:-0.005em;
  margin:0 0 64px
}

.mat-display-1,.mat-typography .mat-display-1{
  font:400 34px/40px var(--font);
  letter-spacing:normal;
  margin:0 0 64px
}

.mat-bottom-sheet-container{
  font:400 14px/20px var(--font);
  letter-spacing:normal
}

.mat-button,.mat-raised-button,.mat-icon-button,.mat-stroked-button,.mat-flat-button,.mat-fab,.mat-mini-fab{
  font-family:var(--font);
  font-size:14px;
  font-weight:500
}

.mat-button-toggle{
  font-family:var(--font)
}

.mat-card{
  font-family:var(--font)
}

.mat-card-title{
  font-size:24px;
  font-weight:500
}

.mat-card-header .mat-card-title{
  font-size:18px
}

.mat-card-subtitle,.mat-card-content{
  font-size:14px
}

.mat-checkbox{
  font-family:var(--font)
}

.mat-checkbox-layout .mat-checkbox-label{
  line-height:24px
}

.mat-chip{
  font-size:14px;
  font-weight:500
}

.mat-chip .mat-chip-trailing-icon.mat-icon,.mat-chip .mat-chip-remove.mat-icon{
  font-size:18px
}

.mat-table{
  font-family:var(--font)
}

.mat-header-cell{
  font-size:12px;
  font-weight:500
}

.mat-cell,.mat-footer-cell{
  font-size:14px
}

.mat-calendar{
  font-family:var(--font)
}

.mat-calendar-body{
  font-size:13px
}

.mat-calendar-body-label,.mat-calendar-period-button{
  font-size:14px;
  font-weight:500
}

.mat-calendar-table-header th{
  font-size:11px;
  font-weight:400
}

.mat-dialog-title{
  font:500 18px/26px var(--font);
  letter-spacing:normal
}

.mat-expansion-panel-header{
  font-family:var(--font);
  font-size:15px;
  font-weight:400
}

.mat-expansion-panel-content{
  font:400 14px/20px var(--font);
  letter-spacing:normal
}

.mat-form-field{
  font:400 14px/1.125 var(--font);
  letter-spacing:normal
}

.mat-form-field-wrapper{
  padding-bottom:1.34375em
}

.mat-form-field-prefix .mat-icon,.mat-form-field-suffix .mat-icon{
  font-size:150%;
  line-height:1.125
}

.mat-form-field-prefix .mat-icon-button,.mat-form-field-suffix .mat-icon-button{
  height:1.5em;
  width:1.5em
}

.mat-form-field-prefix .mat-icon-button .mat-icon,.mat-form-field-suffix .mat-icon-button .mat-icon{
  height:1.125em;
  line-height:1.125
}

.mat-form-field-infix{
  padding:.5em 0;
  border-top:.84375em solid rgba(0,0,0,0)
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-1.34375em) scale(0.75);
  width:133.3333333333%
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-1.34374em) scale(0.75);
  width:133.3333433333%
}

.mat-form-field-label-wrapper{
  top:-0.84375em;
  padding-top:.84375em
}

.mat-form-field-label{
  top:1.34375em
}

.mat-form-field-underline{
  bottom:1.34375em
}

.mat-form-field-subscript-wrapper{
  font-size:75%;
  margin-top:.6666666667em;
  top:calc(100% - 1.7916666667em)
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper{
  padding-bottom:1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-infix{
  padding:.4375em 0
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width:133.3333333333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width:133.3333433333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width:133.3333533333%
}

.mat-form-field-appearance-legacy .mat-form-field-label{
  top:1.28125em
}

.mat-form-field-appearance-legacy .mat-form-field-underline{
  bottom:1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{
  margin-top:.5416666667em;
  top:calc(100% - 1.6666666667em)
}

@media print{
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.28122em) scale(0.75)
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.28121em) scale(0.75)
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.2812em) scale(0.75)
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix{
  padding:.25em 0 .75em 0
}

.mat-form-field-appearance-fill .mat-form-field-label{
  top:1.09375em;
  margin-top:-0.5em
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-0.59375em) scale(0.75);
  width:133.3333333333%
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-0.59374em) scale(0.75);
  width:133.3333433333%
}

.mat-form-field-appearance-outline .mat-form-field-infix{
  padding:1em 0 1em 0
}

.mat-form-field-appearance-outline .mat-form-field-label{
  top:1.84375em;
  margin-top:-0.25em
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-1.59375em) scale(0.75);
  width:133.3333333333%
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{
  transform:translateY(-1.59374em) scale(0.75);
  width:133.3333433333%
}

.mat-grid-tile-header,.mat-grid-tile-footer{
  font-size:14px
}

.mat-grid-tile-header .mat-line,.mat-grid-tile-footer .mat-line{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  display:block;
  box-sizing:border-box
}

.mat-grid-tile-header .mat-line:nth-child(n+2),.mat-grid-tile-footer .mat-line:nth-child(n+2){
  font-size:12px
}

input.mat-input-element{
  margin-top:-0.0625em
}

.mat-menu-item{
  font-family:var(--font);
  font-size:14px;
  font-weight:400
}

.mat-paginator,.mat-paginator-page-size .mat-select-trigger{
  font-family:var(--font);
  font-size:12px
}

.mat-radio-button{
  font-family:var(--font)
}

.mat-select{
  font-family:var(--font)
}

.mat-select-trigger{
  height:1.125em
}

.mat-slide-toggle-content{
  font-family:var(--font)
}

.mat-slider-thumb-label-text{
  font-family:var(--font);
  font-size:12px;
  font-weight:500
}

.mat-stepper-vertical,.mat-stepper-horizontal{
  font-family:var(--font)
}

.mat-step-label{
  font-size:14px;
  font-weight:400
}

.mat-step-sub-label-error{
  font-weight:normal
}

.mat-step-label-error{
  font-size:14px
}

.mat-step-label-selected{
  font-size:14px;
  font-weight:500
}

.mat-tab-group{
  font-family:var(--font)
}

.mat-tab-label,.mat-tab-link{
  font-family:var(--font);
  font-size:14px;
  font-weight:500
}

.mat-toolbar,.mat-toolbar h1,.mat-toolbar h2,.mat-toolbar h3,.mat-toolbar h4,.mat-toolbar h5,.mat-toolbar h6{
  font:500 18px/26px var(--font);
  letter-spacing:normal;
  margin:0
}

.mat-tooltip{
  font-family:var(--font);
  font-size:10px;
  padding-top:6px;
  padding-bottom:6px
}

.mat-tooltip-handset{
  font-size:14px;
  padding-top:8px;
  padding-bottom:8px
}

.mat-list-item{
  font-family:var(--font)
}

.mat-list-option{
  font-family:var(--font)
}

.mat-list-base .mat-list-item{
  font-size:16px
}

.mat-list-base .mat-list-item .mat-line{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  display:block;
  box-sizing:border-box
}

.mat-list-base .mat-list-item .mat-line:nth-child(n+2){
  font-size:14px
}

.mat-list-base .mat-list-option{
  font-size:16px
}

.mat-list-base .mat-list-option .mat-line{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  display:block;
  box-sizing:border-box
}

.mat-list-base .mat-list-option .mat-line:nth-child(n+2){
  font-size:14px
}

.mat-list-base .mat-subheader{
  font-family:var(--font);
  font-size:14px;
  font-weight:500
}

.mat-list-base[dense] .mat-list-item{
  font-size:12px
}

.mat-list-base[dense] .mat-list-item .mat-line{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  display:block;
  box-sizing:border-box
}

.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2){
  font-size:12px
}

.mat-list-base[dense] .mat-list-option{
  font-size:12px
}

.mat-list-base[dense] .mat-list-option .mat-line{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  display:block;
  box-sizing:border-box
}

.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2){
  font-size:12px
}

.mat-list-base[dense] .mat-subheader{
  font-family:var(--font);
  font-size:12px;
  font-weight:500
}

.mat-option{
  font-family:var(--font);
  font-size:16px
}

.mat-optgroup-label{
  font:500 14px/24px var(--font);
  letter-spacing:normal
}

.mat-simple-snackbar{
  font-family:var(--font);
  font-size:14px
}

.mat-simple-snackbar-action{
  line-height:1;
  font-family:inherit;
  font-size:inherit;
  font-weight:500
}

.mat-tree{
  font-family:var(--font)
}

.mat-tree-node,.mat-nested-tree-node{
  font-weight:400;
  font-size:14px
}

.mat-ripple{
  overflow:hidden;
  position:relative
}

.mat-ripple:not(:empty){
  transform:translateZ(0)
}

.mat-ripple.mat-ripple-unbounded{
  overflow:visible
}

.mat-ripple-element{
  position:absolute;
  border-radius:50%;
  pointer-events:none;
  transition:opacity,transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform:scale3d(0, 0, 0)
}

.cdk-high-contrast-active .mat-ripple-element{
  display:none
}

.cdk-visually-hidden{
  border:0;
  clip:rect(0 0 0 0);
  height:1px;
  margin:-1px;
  overflow:hidden;
  padding:0;
  position:absolute;
  width:1px;
  white-space:nowrap;
  outline:0;
  -webkit-appearance:none;
  -moz-appearance:none;
  left:0
}

[dir=rtl] .cdk-visually-hidden{
  left:auto;
  right:0
}

.cdk-overlay-container,.cdk-global-overlay-wrapper{
  pointer-events:none;
  top:0;
  left:0;
  height:100%;
  width:100%
}

.cdk-overlay-container{
  position:fixed;
  z-index:1000
}

.cdk-overlay-container:empty{
  display:none
}

.cdk-global-overlay-wrapper{
  display:flex;
  position:absolute;
  z-index:1000
}

.cdk-overlay-pane{
  position:absolute;
  pointer-events:auto;
  box-sizing:border-box;
  z-index:1000;
  display:flex;
  max-width:100%;
  max-height:100%
}

.cdk-overlay-backdrop{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:1000;
  pointer-events:auto;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity:0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
  opacity:1
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
  opacity:.6
}

.cdk-overlay-dark-backdrop{
  background:rgba(0,0,0,.32)
}

.cdk-overlay-transparent-backdrop{
  transition:visibility 1ms linear,opacity 1ms linear;
  visibility:hidden;
  opacity:1
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{
  opacity:0;
  visibility:visible
}

.cdk-overlay-backdrop-noop-animation{
  transition:none
}

.cdk-overlay-connected-position-bounding-box{
  position:absolute;
  z-index:1000;
  display:flex;
  flex-direction:column;
  min-width:1px;
  min-height:1px
}

.cdk-global-scrollblock{
  position:fixed;
  width:100%;
  overflow-y:scroll
}

textarea.cdk-textarea-autosize{
  resize:none
}

textarea.cdk-textarea-autosize-measuring{
  padding:2px 0 !important;
  box-sizing:content-box !important;
  height:auto !important;
  overflow:hidden !important
}

textarea.cdk-textarea-autosize-measuring-firefox{
  padding:2px 0 !important;
  box-sizing:content-box !important;
  height:0 !important
}

@keyframes cdk-text-field-autofill-start{
  /*!*/
}

@keyframes cdk-text-field-autofill-end{
  /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill{
  animation:cdk-text-field-autofill-start 0s 1ms
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill){
  animation:cdk-text-field-autofill-end 0s 1ms
}

.mat-focus-indicator{
  position:relative
}

.mat-focus-indicator::before{
  top:0;
  left:0;
  right:0;
  bottom:0;
  position:absolute;
  box-sizing:border-box;
  pointer-events:none;
  display:var(--mat-focus-indicator-display, none);
  border:var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius:var(--mat-focus-indicator-border-radius, 4px)
}

.mat-focus-indicator:focus::before{
  content:""
}

.cdk-high-contrast-active{
  --mat-focus-indicator-display: block
}

.mat-mdc-focus-indicator{
  position:relative
}

.mat-mdc-focus-indicator::before{
  top:0;
  left:0;
  right:0;
  bottom:0;
  position:absolute;
  box-sizing:border-box;
  pointer-events:none;
  display:var(--mat-mdc-focus-indicator-display, none);
  border:var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius:var(--mat-mdc-focus-indicator-border-radius, 4px)
}

.mat-mdc-focus-indicator:focus::before{
  content:""
}

.cdk-high-contrast-active{
  --mat-mdc-focus-indicator-display: block
}

.mat-ripple-element{
  background-color:rgba(0,0,0,.1)
}

.mat-option{
  color:#000
}

.mat-option:hover:not(.mat-option-disabled),.mat-option:focus:not(.mat-option-disabled){
  background:rgba(0,0,0,.04)
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled){
  background:rgba(0,0,0,.04)
}

.mat-option.mat-active{
  background:rgba(0,0,0,.04);
  color:#000
}

.mat-option.mat-option-disabled{
  color:rgba(0,0,0,.38)
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
  color:#dc1e35
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled){
  color:#ff4081
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled){
  color:#f44336
}

.mat-optgroup-label{
  color:rgba(0,0,0,.54)
}

.mat-optgroup-disabled .mat-optgroup-label{
  color:rgba(0,0,0,.38)
}

.mat-pseudo-checkbox{
  color:rgba(0,0,0,.54)
}

.mat-pseudo-checkbox::after{
  color:#fafafa
}

.mat-pseudo-checkbox-disabled{
  color:#b0b0b0
}

.mat-primary .mat-pseudo-checkbox-checked,.mat-primary .mat-pseudo-checkbox-indeterminate{
  background:#dc1e35
}

.mat-pseudo-checkbox-checked,.mat-pseudo-checkbox-indeterminate,.mat-accent .mat-pseudo-checkbox-checked,.mat-accent .mat-pseudo-checkbox-indeterminate{
  background:#ff4081
}

.mat-warn .mat-pseudo-checkbox-checked,.mat-warn .mat-pseudo-checkbox-indeterminate{
  background:#f44336
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled{
  background:#b0b0b0
}

.mat-app-background{
  background-color:#fafafa;
  color:#000
}

.mat-elevation-z0{
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z1{
  box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z2{
  box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z3{
  box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z4{
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z5{
  box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z6{
  box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z7{
  box-shadow:0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z8{
  box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z9{
  box-shadow:0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z10{
  box-shadow:0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z11{
  box-shadow:0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z12{
  box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z13{
  box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z14{
  box-shadow:0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z15{
  box-shadow:0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z16{
  box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z17{
  box-shadow:0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z18{
  box-shadow:0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z19{
  box-shadow:0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z20{
  box-shadow:0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z21{
  box-shadow:0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z22{
  box-shadow:0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z23{
  box-shadow:0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z24{
  box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)
}

.mat-theme-loaded-marker{
  display:none
}

.mat-autocomplete-panel{
  background:#fff;
  color:#000
}

.mat-autocomplete-panel:not([class*=mat-elevation-z]){
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover){
  background:#fff
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled){
  color:#000
}

.mat-badge{
  position:relative
}

.mat-badge.mat-badge{
  overflow:visible
}

.mat-badge-hidden .mat-badge-content{
  display:none
}

.mat-badge-content{
  position:absolute;
  text-align:center;
  display:inline-block;
  border-radius:50%;
  transition:transform 200ms ease-in-out;
  transform:scale(0.6);
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  pointer-events:none
}

.ng-animate-disabled .mat-badge-content,.mat-badge-content._mat-animation-noopable{
  transition:none
}

.mat-badge-content.mat-badge-active{
  transform:none
}

.mat-badge-small .mat-badge-content{
  width:16px;
  height:16px;
  line-height:16px
}

.mat-badge-small.mat-badge-above .mat-badge-content{
  top:-8px
}

.mat-badge-small.mat-badge-below .mat-badge-content{
  bottom:-8px
}

.mat-badge-small.mat-badge-before .mat-badge-content{
  left:-16px
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content{
  left:auto;
  right:-16px
}

.mat-badge-small.mat-badge-after .mat-badge-content{
  right:-16px
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content{
  right:auto;
  left:-16px
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:-8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:auto;
  right:-8px
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:-8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:auto;
  left:-8px
}

.mat-badge-medium .mat-badge-content{
  width:22px;
  height:22px;
  line-height:22px
}

.mat-badge-medium.mat-badge-above .mat-badge-content{
  top:-11px
}

.mat-badge-medium.mat-badge-below .mat-badge-content{
  bottom:-11px
}

.mat-badge-medium.mat-badge-before .mat-badge-content{
  left:-22px
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content{
  left:auto;
  right:-22px
}

.mat-badge-medium.mat-badge-after .mat-badge-content{
  right:-22px
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content{
  right:auto;
  left:-22px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:-11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:auto;
  right:-11px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:-11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:auto;
  left:-11px
}

.mat-badge-large .mat-badge-content{
  width:28px;
  height:28px;
  line-height:28px
}

.mat-badge-large.mat-badge-above .mat-badge-content{
  top:-14px
}

.mat-badge-large.mat-badge-below .mat-badge-content{
  bottom:-14px
}

.mat-badge-large.mat-badge-before .mat-badge-content{
  left:-28px
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content{
  left:auto;
  right:-28px
}

.mat-badge-large.mat-badge-after .mat-badge-content{
  right:-28px
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content{
  right:auto;
  left:-28px
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:-14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left:auto;
  right:-14px
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:-14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content{
  right:auto;
  left:-14px
}

.mat-badge-content{
  color:#fff;
  background:#dc1e35
}

.cdk-high-contrast-active .mat-badge-content{
  outline:solid 1px;
  border-radius:0
}

.mat-badge-accent .mat-badge-content{
  background:#ff4081;
  color:#fff
}

.mat-badge-warn .mat-badge-content{
  color:#fff;
  background:#f44336
}

.mat-badge-disabled .mat-badge-content{
  background:#b9b9b9;
  color:rgba(0,0,0,.38)
}

.mat-bottom-sheet-container{
  box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background:#fff;
  color:#000
}

.mat-button,.mat-icon-button,.mat-stroked-button{
  color:inherit;
  background:rgba(0,0,0,0)
}

.mat-button.mat-primary,.mat-icon-button.mat-primary,.mat-stroked-button.mat-primary{
  color:#dc1e35
}

.mat-button.mat-accent,.mat-icon-button.mat-accent,.mat-stroked-button.mat-accent{
  color:#ff4081
}

.mat-button.mat-warn,.mat-icon-button.mat-warn,.mat-stroked-button.mat-warn{
  color:#f44336
}

.mat-button.mat-primary.mat-button-disabled,.mat-button.mat-accent.mat-button-disabled,.mat-button.mat-warn.mat-button-disabled,.mat-button.mat-button-disabled.mat-button-disabled,.mat-icon-button.mat-primary.mat-button-disabled,.mat-icon-button.mat-accent.mat-button-disabled,.mat-icon-button.mat-warn.mat-button-disabled,.mat-icon-button.mat-button-disabled.mat-button-disabled,.mat-stroked-button.mat-primary.mat-button-disabled,.mat-stroked-button.mat-accent.mat-button-disabled,.mat-stroked-button.mat-warn.mat-button-disabled,.mat-stroked-button.mat-button-disabled.mat-button-disabled{
  color:rgba(0,0,0,.26)
}

.mat-button.mat-primary .mat-button-focus-overlay,.mat-icon-button.mat-primary .mat-button-focus-overlay,.mat-stroked-button.mat-primary .mat-button-focus-overlay{
  background-color:#dc1e35
}

.mat-button.mat-accent .mat-button-focus-overlay,.mat-icon-button.mat-accent .mat-button-focus-overlay,.mat-stroked-button.mat-accent .mat-button-focus-overlay{
  background-color:#ff4081
}

.mat-button.mat-warn .mat-button-focus-overlay,.mat-icon-button.mat-warn .mat-button-focus-overlay,.mat-stroked-button.mat-warn .mat-button-focus-overlay{
  background-color:#f44336
}

.mat-button.mat-button-disabled .mat-button-focus-overlay,.mat-icon-button.mat-button-disabled .mat-button-focus-overlay,.mat-stroked-button.mat-button-disabled .mat-button-focus-overlay{
  background-color:rgba(0,0,0,0)
}

.mat-button .mat-ripple-element,.mat-icon-button .mat-ripple-element,.mat-stroked-button .mat-ripple-element{
  opacity:.1;
  background-color:currentColor
}

.mat-button-focus-overlay{
  background:#000
}

.mat-stroked-button:not(.mat-button-disabled){
  border-color:rgba(82,63,105,.06)
}

.mat-flat-button,.mat-raised-button,.mat-fab,.mat-mini-fab{
  color:#000;
  background-color:#fff
}

.mat-flat-button.mat-primary,.mat-raised-button.mat-primary,.mat-fab.mat-primary,.mat-mini-fab.mat-primary{
  color:#fff
}

.mat-flat-button.mat-accent,.mat-raised-button.mat-accent,.mat-fab.mat-accent,.mat-mini-fab.mat-accent{
  color:#fff
}

.mat-flat-button.mat-warn,.mat-raised-button.mat-warn,.mat-fab.mat-warn,.mat-mini-fab.mat-warn{
  color:#fff
}

.mat-flat-button.mat-primary.mat-button-disabled,.mat-flat-button.mat-accent.mat-button-disabled,.mat-flat-button.mat-warn.mat-button-disabled,.mat-flat-button.mat-button-disabled.mat-button-disabled,.mat-raised-button.mat-primary.mat-button-disabled,.mat-raised-button.mat-accent.mat-button-disabled,.mat-raised-button.mat-warn.mat-button-disabled,.mat-raised-button.mat-button-disabled.mat-button-disabled,.mat-fab.mat-primary.mat-button-disabled,.mat-fab.mat-accent.mat-button-disabled,.mat-fab.mat-warn.mat-button-disabled,.mat-fab.mat-button-disabled.mat-button-disabled,.mat-mini-fab.mat-primary.mat-button-disabled,.mat-mini-fab.mat-accent.mat-button-disabled,.mat-mini-fab.mat-warn.mat-button-disabled,.mat-mini-fab.mat-button-disabled.mat-button-disabled{
  color:rgba(0,0,0,.26)
}

.mat-flat-button.mat-primary,.mat-raised-button.mat-primary,.mat-fab.mat-primary,.mat-mini-fab.mat-primary{
  background-color:#dc1e35
}

.mat-flat-button.mat-accent,.mat-raised-button.mat-accent,.mat-fab.mat-accent,.mat-mini-fab.mat-accent{
  background-color:#ff4081
}

.mat-flat-button.mat-warn,.mat-raised-button.mat-warn,.mat-fab.mat-warn,.mat-mini-fab.mat-warn{
  background-color:#f44336
}

.mat-flat-button.mat-primary.mat-button-disabled,.mat-flat-button.mat-accent.mat-button-disabled,.mat-flat-button.mat-warn.mat-button-disabled,.mat-flat-button.mat-button-disabled.mat-button-disabled,.mat-raised-button.mat-primary.mat-button-disabled,.mat-raised-button.mat-accent.mat-button-disabled,.mat-raised-button.mat-warn.mat-button-disabled,.mat-raised-button.mat-button-disabled.mat-button-disabled,.mat-fab.mat-primary.mat-button-disabled,.mat-fab.mat-accent.mat-button-disabled,.mat-fab.mat-warn.mat-button-disabled,.mat-fab.mat-button-disabled.mat-button-disabled,.mat-mini-fab.mat-primary.mat-button-disabled,.mat-mini-fab.mat-accent.mat-button-disabled,.mat-mini-fab.mat-warn.mat-button-disabled,.mat-mini-fab.mat-button-disabled.mat-button-disabled{
  background-color:rgba(0,0,0,.12)
}

.mat-flat-button.mat-primary .mat-ripple-element,.mat-raised-button.mat-primary .mat-ripple-element,.mat-fab.mat-primary .mat-ripple-element,.mat-mini-fab.mat-primary .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.mat-flat-button.mat-accent .mat-ripple-element,.mat-raised-button.mat-accent .mat-ripple-element,.mat-fab.mat-accent .mat-ripple-element,.mat-mini-fab.mat-accent .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.mat-flat-button.mat-warn .mat-ripple-element,.mat-raised-button.mat-warn .mat-ripple-element,.mat-fab.mat-warn .mat-ripple-element,.mat-mini-fab.mat-warn .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.mat-stroked-button:not([class*=mat-elevation-z]),.mat-flat-button:not([class*=mat-elevation-z]){
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-raised-button:not([class*=mat-elevation-z]){
  box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]){
  box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]){
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-fab:not([class*=mat-elevation-z]),.mat-mini-fab:not([class*=mat-elevation-z]){
  box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),.mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]){
  box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}

.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]),.mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]){
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),.mat-button-toggle-group:not([class*=mat-elevation-z]){
  box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]){
  box-shadow:none
}

.mat-button-toggle{
  color:rgba(0,0,0,.38)
}

.mat-button-toggle .mat-button-toggle-focus-overlay{
  background-color:rgba(0,0,0,.12)
}

.mat-button-toggle-appearance-standard{
  color:#000;
  background:#fff
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay{
  background-color:#000
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle{
  border-left:solid 1px #f5f3f6
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle{
  border-left:none;
  border-right:solid 1px #f5f3f6
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle+.mat-button-toggle{
  border-left:none;
  border-right:none;
  border-top:solid 1px #f5f3f6
}

.mat-button-toggle-checked{
  background-color:#e0e0e0;
  color:rgba(0,0,0,.54)
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard{
  color:#000
}

.mat-button-toggle-disabled{
  color:rgba(0,0,0,.26);
  background-color:#eee
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard{
  background:#fff
}

.mat-button-toggle-disabled.mat-button-toggle-checked{
  background-color:#bdbdbd
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,.mat-button-toggle-group-appearance-standard{
  border:solid 1px #f5f3f6
}

.mat-card{
  background:#fff;
  color:#000
}

.mat-card:not([class*=mat-elevation-z]){
  box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.mat-card.mat-card-flat:not([class*=mat-elevation-z]){
  box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-card-subtitle{
  color:rgba(0,0,0,.54)
}

.mat-checkbox-frame{
  border-color:rgba(0,0,0,.54)
}

.mat-checkbox-checkmark{
  fill:#fafafa
}

.mat-checkbox-checkmark-path{
  stroke:#fafafa !important
}

.mat-checkbox-mixedmark{
  background-color:#fafafa
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,.mat-checkbox-checked.mat-primary .mat-checkbox-background{
  background-color:#dc1e35
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,.mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color:#ff4081
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,.mat-checkbox-checked.mat-warn .mat-checkbox-background{
  background-color:#f44336
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{
  background-color:#b0b0b0
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame{
  border-color:#b0b0b0
}

.mat-checkbox-disabled .mat-checkbox-label{
  color:rgba(0,0,0,.38)
}

.mat-checkbox .mat-ripple-element{
  background-color:#000
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element{
  background:#dc1e35
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{
  background:#ff4081
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element{
  background:#f44336
}

.mat-chip.mat-standard-chip{
  background-color:#e0e0e0;
  color:#000
}

.mat-chip.mat-standard-chip .mat-chip-remove{
  color:#000;
  opacity:.4
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active{
  box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover{
  opacity:.54
}

.mat-chip.mat-standard-chip.mat-chip-disabled{
  opacity:.4
}

.mat-chip.mat-standard-chip::after{
  background:#000
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary{
  background-color:#dc1e35;
  color:#fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove{
  color:#fff;
  opacity:.4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn{
  background-color:#f44336;
  color:#fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove{
  color:#fff;
  opacity:.4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent{
  background-color:#ff4081;
  color:#fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove{
  color:#fff;
  opacity:.4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element{
  background-color:rgba(255,255,255,.1)
}

.mat-table{
  background:#fff
}

.mat-table thead,.mat-table tbody,.mat-table tfoot,mat-header-row,mat-row,mat-footer-row,[mat-header-row],[mat-row],[mat-footer-row],.mat-table-sticky{
  background:inherit
}

mat-row,mat-header-row,mat-footer-row,th.mat-header-cell,td.mat-cell,td.mat-footer-cell{
  border-bottom-color:rgba(82,63,105,.06)
}

.mat-header-cell{
  color:rgba(0,0,0,.54)
}

.mat-cell,.mat-footer-cell{
  color:#000
}

.mat-calendar-arrow{
  fill:rgba(0,0,0,.54)
}

.mat-datepicker-toggle,.mat-datepicker-content .mat-calendar-next-button,.mat-datepicker-content .mat-calendar-previous-button{
  color:rgba(0,0,0,.54)
}

.mat-calendar-table-header-divider::after{
  background:rgba(82,63,105,.06)
}

.mat-calendar-table-header,.mat-calendar-body-label{
  color:rgba(0,0,0,.54)
}

.mat-calendar-body-cell-content,.mat-date-range-input-separator{
  color:#000;
  border-color:rgba(0,0,0,0)
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  color:rgba(0,0,0,.38)
}

.mat-form-field-disabled .mat-date-range-input-separator{
  color:rgba(0,0,0,.38)
}

.mat-calendar-body-in-preview{
  color:rgba(82,63,105,.12)
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color:rgba(0,0,0,.38)
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color:rgba(0,0,0,.18)
}

.mat-calendar-body-in-range::before{
  background:rgba(220,30,53,.2)
}

.mat-calendar-body-comparison-identical,.mat-calendar-body-in-comparison-range::before{
  background:rgba(249,171,0,.2)
}

.mat-calendar-body-comparison-bridge-start::before,[dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
  background:linear-gradient(to right, rgba(220, 30, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-calendar-body-comparison-bridge-end::before,[dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
  background:linear-gradient(to left, rgba(220, 30, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
  background:#a8dab5
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
  background:#46a35e
}

.mat-calendar-body-selected{
  background-color:#dc1e35;
  color:#fff
}

.mat-calendar-body-disabled>.mat-calendar-body-selected{
  background-color:rgba(220,30,53,.4)
}

.mat-calendar-body-today.mat-calendar-body-selected{
  box-shadow:inset 0 0 0 1px #fff
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color:rgba(220,30,53,.3)
}

@media(hover: hover){
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(220,30,53,.3)
  }
}

.mat-datepicker-content{
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color:#fff;
  color:#000
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before{
  background:rgba(255,64,129,.2)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before{
  background:rgba(249,171,0,.2)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
  background:linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
  background:linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
  background:#a8dab5
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
  background:#46a35e
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected{
  background-color:#ff4081;
  color:#fff
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled>.mat-calendar-body-selected{
  background-color:rgba(255,64,129,.4)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected{
  box-shadow:inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color:rgba(255,64,129,.3)
}

@media(hover: hover){
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(255,64,129,.3)
  }
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before{
  background:rgba(244,67,54,.2)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before{
  background:rgba(249,171,0,.2)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
  background:linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
  background:linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
  background:#a8dab5
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
  background:#46a35e
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected{
  background-color:#f44336;
  color:#fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled>.mat-calendar-body-selected{
  background-color:rgba(244,67,54,.4)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected{
  box-shadow:inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color:rgba(244,67,54,.3)
}

@media(hover: hover){
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(244,67,54,.3)
  }
}

.mat-datepicker-content-touch{
  box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)
}

.mat-datepicker-toggle-active{
  color:#dc1e35
}

.mat-datepicker-toggle-active.mat-accent{
  color:#ff4081
}

.mat-datepicker-toggle-active.mat-warn{
  color:#f44336
}

.mat-date-range-input-inner[disabled]{
  color:rgba(0,0,0,.38)
}

.mat-dialog-container{
  box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background:#fff;
  color:#000
}

.mat-divider{
  border-top-color:rgba(82,63,105,.06)
}

.mat-divider-vertical{
  border-right-color:rgba(82,63,105,.06)
}

.mat-expansion-panel{
  background:#fff;
  color:#000
}

.mat-expansion-panel:not([class*=mat-elevation-z]){
  box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-action-row{
  border-top-color:rgba(82,63,105,.06)
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]){
  background:rgba(0,0,0,.04)
}

@media(hover: none){
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover{
    background:#fff
  }
}

.mat-expansion-panel-header-title{
  color:#000
}

.mat-expansion-panel-header-description,.mat-expansion-indicator::after{
  color:rgba(0,0,0,.54)
}

.mat-expansion-panel-header[aria-disabled=true]{
  color:rgba(0,0,0,.26)
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description{
  color:inherit
}

.mat-form-field-label{
  color:rgba(0,0,0,.6)
}

.mat-hint{
  color:rgba(0,0,0,.6)
}

.mat-form-field.mat-focused .mat-form-field-label{
  color:#dc1e35
}

.mat-form-field.mat-focused .mat-form-field-label.mat-accent{
  color:#ff4081
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn{
  color:#f44336
}

.mat-focused .mat-form-field-required-marker{
  color:#ff4081
}

.mat-form-field-ripple{
  background-color:rgba(82,63,105,.87)
}

.mat-form-field.mat-focused .mat-form-field-ripple{
  background-color:#dc1e35
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent{
  background-color:#ff4081
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn{
  background-color:#f44336
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after{
  color:#dc1e35
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after{
  color:#ff4081
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after{
  color:#f44336
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label{
  color:#f44336
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
  color:#f44336
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent{
  background-color:#f44336
}

.mat-error{
  color:#f44336
}

.mat-form-field-appearance-legacy .mat-form-field-label{
  color:rgba(0,0,0,.54)
}

.mat-form-field-appearance-legacy .mat-hint{
  color:rgba(0,0,0,.54)
}

.mat-form-field-appearance-legacy .mat-form-field-underline{
  background-color:rgba(82,63,105,.42)
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{
  background-image:linear-gradient(to right, rgba(82, 63, 105, 0.42) 0%, rgba(82, 63, 105, 0.42) 33%, transparent 0%);
  background-size:4px 100%;
  background-repeat:repeat-x
}

.mat-form-field-appearance-standard .mat-form-field-underline{
  background-color:rgba(82,63,105,.42)
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline{
  background-image:linear-gradient(to right, rgba(82, 63, 105, 0.42) 0%, rgba(82, 63, 105, 0.42) 33%, transparent 0%);
  background-size:4px 100%;
  background-repeat:repeat-x
}

.mat-form-field-appearance-fill .mat-form-field-flex{
  background-color:rgba(0,0,0,.04)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex{
  background-color:rgba(0,0,0,.02)
}

.mat-form-field-appearance-fill .mat-form-field-underline::before{
  background-color:rgba(82,63,105,.42)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label{
  color:rgba(0,0,0,.38)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before{
  background-color:rgba(0,0,0,0)
}

.mat-form-field-appearance-outline .mat-form-field-outline{
  color:rgba(82,63,105,.12)
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick{
  color:rgba(82,63,105,.87)
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
  color:#dc1e35
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick{
  color:#ff4081
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick{
  color:#f44336
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
  color:#f44336
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label{
  color:rgba(0,0,0,.38)
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline{
  color:rgba(82,63,105,.06)
}

.mat-icon.mat-primary{
  color:#dc1e35
}

.mat-icon.mat-accent{
  color:#ff4081
}

.mat-icon.mat-warn{
  color:#f44336
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after{
  color:rgba(0,0,0,.54)
}

.mat-input-element:disabled,.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
  color:rgba(0,0,0,.38)
}

.mat-input-element{
  caret-color:#dc1e35
}

.mat-input-element::placeholder{
  color:rgba(0,0,0,.42)
}

.mat-input-element::-moz-placeholder{
  color:rgba(0,0,0,.42)
}

.mat-input-element::-webkit-input-placeholder{
  color:rgba(0,0,0,.42)
}

.mat-input-element:-ms-input-placeholder{
  color:rgba(0,0,0,.42)
}

.mat-form-field.mat-accent .mat-input-element{
  caret-color:#ff4081
}

.mat-form-field.mat-warn .mat-input-element,.mat-form-field-invalid .mat-input-element{
  caret-color:#f44336
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after{
  color:#f44336
}

.mat-list-base .mat-list-item{
  color:#000
}

.mat-list-base .mat-list-option{
  color:#000
}

.mat-list-base .mat-subheader{
  color:rgba(0,0,0,.54)
}

.mat-list-base .mat-list-item-disabled{
  background-color:#eee;
  color:rgba(0,0,0,.38)
}

.mat-list-option:hover,.mat-list-option:focus,.mat-nav-list .mat-list-item:hover,.mat-nav-list .mat-list-item:focus,.mat-action-list .mat-list-item:hover,.mat-action-list .mat-list-item:focus{
  background:rgba(0,0,0,.04)
}

.mat-list-single-selected-option,.mat-list-single-selected-option:hover,.mat-list-single-selected-option:focus{
  background:rgba(0,0,0,.12)
}

.mat-menu-panel{
  background:#fff
}

.mat-menu-panel:not([class*=mat-elevation-z]){
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-menu-item{
  background:rgba(0,0,0,0);
  color:#000
}

.mat-menu-item[disabled],.mat-menu-item[disabled] .mat-menu-submenu-icon,.mat-menu-item[disabled] .mat-icon-no-color{
  color:rgba(0,0,0,.38)
}

.mat-menu-item .mat-icon-no-color,.mat-menu-submenu-icon{
  color:rgba(0,0,0,.54)
}

.mat-menu-item:hover:not([disabled]),.mat-menu-item.cdk-program-focused:not([disabled]),.mat-menu-item.cdk-keyboard-focused:not([disabled]),.mat-menu-item-highlighted:not([disabled]){
  background:rgba(0,0,0,.04)
}

.mat-paginator{
  background:#fff
}

.mat-paginator,.mat-paginator-page-size .mat-select-trigger{
  color:rgba(0,0,0,.54)
}

.mat-paginator-decrement,.mat-paginator-increment{
  border-top:2px solid rgba(0,0,0,.54);
  border-right:2px solid rgba(0,0,0,.54)
}

.mat-paginator-first,.mat-paginator-last{
  border-top:2px solid rgba(0,0,0,.54)
}

.mat-icon-button[disabled] .mat-paginator-decrement,.mat-icon-button[disabled] .mat-paginator-increment,.mat-icon-button[disabled] .mat-paginator-first,.mat-icon-button[disabled] .mat-paginator-last{
  border-color:rgba(0,0,0,.38)
}

.mat-progress-bar-background{
  fill:#f3c3c9
}

.mat-progress-bar-buffer{
  background-color:#f3c3c9
}

.mat-progress-bar-fill::after{
  background-color:#dc1e35
}

.mat-progress-bar.mat-accent .mat-progress-bar-background{
  fill:#fbccdc
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer{
  background-color:#fbccdc
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after{
  background-color:#ff4081
}

.mat-progress-bar.mat-warn .mat-progress-bar-background{
  fill:#f9ccc9
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer{
  background-color:#f9ccc9
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after{
  background-color:#f44336
}

.mat-progress-spinner circle,.mat-spinner circle{
  stroke:#dc1e35
}

.mat-progress-spinner.mat-accent circle,.mat-spinner.mat-accent circle{
  stroke:#ff4081
}

.mat-progress-spinner.mat-warn circle,.mat-spinner.mat-warn circle{
  stroke:#f44336
}

.mat-radio-outer-circle{
  border-color:rgba(0,0,0,.54)
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle{
  border-color:#dc1e35
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple{
  background-color:#dc1e35
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:#ff4081
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
  background-color:#ff4081
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle{
  border-color:#f44336
}

.mat-radio-button.mat-warn .mat-radio-inner-circle,.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple{
  background-color:#f44336
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle{
  border-color:rgba(0,0,0,.38)
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle{
  background-color:rgba(0,0,0,.38)
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content{
  color:rgba(0,0,0,.38)
}

.mat-radio-button .mat-ripple-element{
  background-color:#000
}

.mat-select-value{
  color:#000
}

.mat-select-placeholder{
  color:rgba(0,0,0,.42)
}

.mat-select-disabled .mat-select-value{
  color:rgba(0,0,0,.38)
}

.mat-select-arrow{
  color:rgba(0,0,0,.54)
}

.mat-select-panel{
  background:#fff
}

.mat-select-panel:not([class*=mat-elevation-z]){
  box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple){
  background:rgba(0,0,0,.12)
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow{
  color:#dc1e35
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow{
  color:#ff4081
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow{
  color:#f44336
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow{
  color:#f44336
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow{
  color:rgba(0,0,0,.38)
}

.mat-drawer-container{
  background-color:#fafafa;
  color:#000
}

.mat-drawer{
  background-color:#fff;
  color:#000
}

.mat-drawer.mat-drawer-push{
  background-color:#fff
}

.mat-drawer:not(.mat-drawer-side){
  box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}

.mat-drawer-side{
  border-right:solid 1px rgba(82,63,105,.06)
}

.mat-drawer-side.mat-drawer-end{
  border-left:solid 1px rgba(82,63,105,.06);
  border-right:none
}

[dir=rtl] .mat-drawer-side{
  border-left:solid 1px rgba(82,63,105,.06);
  border-right:none
}

[dir=rtl] .mat-drawer-side.mat-drawer-end{
  border-left:none;
  border-right:solid 1px rgba(82,63,105,.06)
}

.mat-drawer-backdrop.mat-drawer-shown{
  background-color:rgba(0,0,0,.6)
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
  background-color:#ff4081
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
  background-color:rgba(255,64,129,.54)
}

.mat-slide-toggle.mat-checked .mat-ripple-element{
  background-color:#ff4081
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb{
  background-color:#dc1e35
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar{
  background-color:rgba(220,30,53,.54)
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element{
  background-color:#dc1e35
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb{
  background-color:#f44336
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar{
  background-color:rgba(244,67,54,.54)
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element{
  background-color:#f44336
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element{
  background-color:#000
}

.mat-slide-toggle-thumb{
  box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color:#fafafa
}

.mat-slide-toggle-bar{
  background-color:rgba(0,0,0,.38)
}

.mat-slider-track-background{
  background-color:rgba(0,0,0,.26)
}

.mat-slider.mat-primary .mat-slider-track-fill,.mat-slider.mat-primary .mat-slider-thumb,.mat-slider.mat-primary .mat-slider-thumb-label{
  background-color:#dc1e35
}

.mat-slider.mat-primary .mat-slider-thumb-label-text{
  color:#fff
}

.mat-slider.mat-primary .mat-slider-focus-ring{
  background-color:rgba(220,30,53,.2)
}

.mat-slider.mat-accent .mat-slider-track-fill,.mat-slider.mat-accent .mat-slider-thumb,.mat-slider.mat-accent .mat-slider-thumb-label{
  background-color:#ff4081
}

.mat-slider.mat-accent .mat-slider-thumb-label-text{
  color:#fff
}

.mat-slider.mat-accent .mat-slider-focus-ring{
  background-color:rgba(255,64,129,.2)
}

.mat-slider.mat-warn .mat-slider-track-fill,.mat-slider.mat-warn .mat-slider-thumb,.mat-slider.mat-warn .mat-slider-thumb-label{
  background-color:#f44336
}

.mat-slider.mat-warn .mat-slider-thumb-label-text{
  color:#fff
}

.mat-slider.mat-warn .mat-slider-focus-ring{
  background-color:rgba(244,67,54,.2)
}

.mat-slider:hover .mat-slider-track-background,.mat-slider.cdk-focused .mat-slider-track-background{
  background-color:rgba(0,0,0,.38)
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,.mat-slider.mat-slider-disabled .mat-slider-track-fill,.mat-slider.mat-slider-disabled .mat-slider-thumb{
  background-color:rgba(0,0,0,.26)
}

.mat-slider.mat-slider-disabled:hover .mat-slider-track-background{
  background-color:rgba(0,0,0,.26)
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring{
  background-color:rgba(0,0,0,.12)
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label{
  background-color:rgba(0,0,0,.87)
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label{
  background-color:rgba(0,0,0,.26)
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb{
  border-color:rgba(0,0,0,.26);
  background-color:rgba(0,0,0,0)
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb,.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb{
  border-color:rgba(0,0,0,.38)
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb,.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb{
  border-color:rgba(0,0,0,.26)
}

.mat-slider-has-ticks .mat-slider-wrapper::after{
  border-color:rgba(0,0,0,.7)
}

.mat-slider-horizontal .mat-slider-ticks{
  background-image:repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image:-moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent)
}

.mat-slider-vertical .mat-slider-ticks{
  background-image:repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent)
}

.mat-step-header.cdk-keyboard-focused,.mat-step-header.cdk-program-focused,.mat-step-header:hover:not([aria-disabled]),.mat-step-header:hover[aria-disabled=false]{
  background-color:rgba(0,0,0,.04)
}

.mat-step-header:hover[aria-disabled=true]{
  cursor:default
}

@media(hover: none){
  .mat-step-header:hover{
    background:none
  }
}

.mat-step-header .mat-step-label,.mat-step-header .mat-step-optional{
  color:rgba(0,0,0,.54)
}

.mat-step-header .mat-step-icon{
  background-color:rgba(0,0,0,.54);
  color:#fff
}

.mat-step-header .mat-step-icon-selected,.mat-step-header .mat-step-icon-state-done,.mat-step-header .mat-step-icon-state-edit{
  background-color:#dc1e35;
  color:#fff
}

.mat-step-header.mat-accent .mat-step-icon{
  color:#fff
}

.mat-step-header.mat-accent .mat-step-icon-selected,.mat-step-header.mat-accent .mat-step-icon-state-done,.mat-step-header.mat-accent .mat-step-icon-state-edit{
  background-color:#ff4081;
  color:#fff
}

.mat-step-header.mat-warn .mat-step-icon{
  color:#fff
}

.mat-step-header.mat-warn .mat-step-icon-selected,.mat-step-header.mat-warn .mat-step-icon-state-done,.mat-step-header.mat-warn .mat-step-icon-state-edit{
  background-color:#f44336;
  color:#fff
}

.mat-step-header .mat-step-icon-state-error{
  background-color:rgba(0,0,0,0);
  color:#f44336
}

.mat-step-header .mat-step-label.mat-step-label-active{
  color:#000
}

.mat-step-header .mat-step-label.mat-step-label-error{
  color:#f44336
}

.mat-stepper-horizontal,.mat-stepper-vertical{
  background-color:#fff
}

.mat-stepper-vertical-line::before{
  border-left-color:rgba(82,63,105,.06)
}

.mat-horizontal-stepper-header::before,.mat-horizontal-stepper-header::after,.mat-stepper-horizontal-line{
  border-top-color:rgba(82,63,105,.06)
}

.mat-sort-header-arrow{
  color:#757575
}

.mat-tab-nav-bar,.mat-tab-header{
  border-bottom:1px solid rgba(82,63,105,.06)
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,.mat-tab-group-inverted-header .mat-tab-header{
  border-top:1px solid rgba(82,63,105,.06);
  border-bottom:none
}

.mat-tab-label,.mat-tab-link{
  color:#000
}

.mat-tab-label.mat-tab-disabled,.mat-tab-link.mat-tab-disabled{
  color:rgba(0,0,0,.38)
}

.mat-tab-header-pagination-chevron{
  border-color:#000
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
  border-color:rgba(0,0,0,.38)
}

.mat-tab-group[class*=mat-background-]>.mat-tab-header,.mat-tab-nav-bar[class*=mat-background-]{
  border-bottom:none;
  border-top:none
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(245,188,194,.3)
}

.mat-tab-group.mat-primary .mat-ink-bar,.mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background-color:#dc1e35
}

.mat-tab-group.mat-primary.mat-background-primary>.mat-tab-header .mat-ink-bar,.mat-tab-group.mat-primary.mat-background-primary>.mat-tab-link-container .mat-ink-bar,.mat-tab-nav-bar.mat-primary.mat-background-primary>.mat-tab-header .mat-ink-bar,.mat-tab-nav-bar.mat-primary.mat-background-primary>.mat-tab-link-container .mat-ink-bar{
  background-color:#fff
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(255,128,171,.3)
}

.mat-tab-group.mat-accent .mat-ink-bar,.mat-tab-nav-bar.mat-accent .mat-ink-bar{
  background-color:#ff4081
}

.mat-tab-group.mat-accent.mat-background-accent>.mat-tab-header .mat-ink-bar,.mat-tab-group.mat-accent.mat-background-accent>.mat-tab-link-container .mat-ink-bar,.mat-tab-nav-bar.mat-accent.mat-background-accent>.mat-tab-header .mat-ink-bar,.mat-tab-nav-bar.mat-accent.mat-background-accent>.mat-tab-link-container .mat-ink-bar{
  background-color:#fff
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(255,205,210,.3)
}

.mat-tab-group.mat-warn .mat-ink-bar,.mat-tab-nav-bar.mat-warn .mat-ink-bar{
  background-color:#f44336
}

.mat-tab-group.mat-warn.mat-background-warn>.mat-tab-header .mat-ink-bar,.mat-tab-group.mat-warn.mat-background-warn>.mat-tab-link-container .mat-ink-bar,.mat-tab-nav-bar.mat-warn.mat-background-warn>.mat-tab-header .mat-ink-bar,.mat-tab-nav-bar.mat-warn.mat-background-warn>.mat-tab-link-container .mat-ink-bar{
  background-color:#fff
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(245,188,194,.3)
}

.mat-tab-group.mat-background-primary>.mat-tab-header,.mat-tab-group.mat-background-primary>.mat-tab-link-container,.mat-tab-group.mat-background-primary>.mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination{
  background-color:#dc1e35
}

.mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-label,.mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-tab-link,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-label,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-tab-link{
  color:#fff
}

.mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
  color:rgba(255,255,255,.4)
}

.mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-primary>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-group.mat-background-primary>.mat-tab-header .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-focus-indicator::before{
  border-color:#fff
}

.mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-primary>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
  border-color:#fff;
  opacity:.4
}

.mat-tab-group.mat-background-primary>.mat-tab-header .mat-ripple-element,.mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-ripple-element,.mat-tab-group.mat-background-primary>.mat-tab-header-pagination .mat-ripple-element,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-ripple-element,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-ripple-element,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination .mat-ripple-element{
  background-color:#fff;
  opacity:.12
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(255,128,171,.3)
}

.mat-tab-group.mat-background-accent>.mat-tab-header,.mat-tab-group.mat-background-accent>.mat-tab-link-container,.mat-tab-group.mat-background-accent>.mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination{
  background-color:#ff4081
}

.mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-label,.mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-tab-link,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-label,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-tab-link{
  color:#fff
}

.mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
  color:rgba(255,255,255,.4)
}

.mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-accent>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-group.mat-background-accent>.mat-tab-header .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-focus-indicator::before{
  border-color:#fff
}

.mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-accent>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
  border-color:#fff;
  opacity:.4
}

.mat-tab-group.mat-background-accent>.mat-tab-header .mat-ripple-element,.mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-ripple-element,.mat-tab-group.mat-background-accent>.mat-tab-header-pagination .mat-ripple-element,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-ripple-element,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-ripple-element,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination .mat-ripple-element{
  background-color:#fff;
  opacity:.12
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){
  background-color:rgba(255,205,210,.3)
}

.mat-tab-group.mat-background-warn>.mat-tab-header,.mat-tab-group.mat-background-warn>.mat-tab-link-container,.mat-tab-group.mat-background-warn>.mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination{
  background-color:#f44336
}

.mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-label,.mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-tab-link,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-label,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-tab-link{
  color:#fff
}

.mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
  color:rgba(255,255,255,.4)
}

.mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-warn>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-group.mat-background-warn>.mat-tab-header .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-focus-indicator::before{
  border-color:#fff
}

.mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-warn>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
  border-color:#fff;
  opacity:.4
}

.mat-tab-group.mat-background-warn>.mat-tab-header .mat-ripple-element,.mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-ripple-element,.mat-tab-group.mat-background-warn>.mat-tab-header-pagination .mat-ripple-element,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-ripple-element,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-ripple-element,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination .mat-ripple-element{
  background-color:#fff;
  opacity:.12
}

.mat-toolbar{
  background:#ebebee;
  color:#000
}

.mat-toolbar.mat-primary{
  background:#dc1e35;
  color:#fff
}

.mat-toolbar.mat-accent{
  background:#ff4081;
  color:#fff
}

.mat-toolbar.mat-warn{
  background:#f44336;
  color:#fff
}

.mat-toolbar .mat-form-field-underline,.mat-toolbar .mat-form-field-ripple,.mat-toolbar .mat-focused .mat-form-field-ripple{
  background-color:currentColor
}

.mat-toolbar .mat-form-field-label,.mat-toolbar .mat-focused .mat-form-field-label,.mat-toolbar .mat-select-value,.mat-toolbar .mat-select-arrow,.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow{
  color:inherit
}

.mat-toolbar .mat-input-element{
  caret-color:currentColor
}

.mat-tooltip{
  background:rgba(97,97,97,.9)
}

.mat-tree{
  background:#fff
}

.mat-tree-node,.mat-nested-tree-node{
  color:#000
}

.mat-snack-bar-container{
  color:rgba(255,255,255,.7);
  background:#323232;
  box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.mat-simple-snackbar-action{
  color:#ff4081
}

html{
  box-sizing:border-box;
  font-size:16px;
  height:100%;
  overflow:hidden
}

body{
  height:100%;
  font:400 14px/20px var(--font);
  letter-spacing:normal;
  color: var(--text-color);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

div{
  box-sizing:border-box
}

:root{
  --sidenav-background: var(--sidenav-background);
  --sidenav-item-background-active: #202738
}

input.mat-input-element{
  line-height: 1.5
}

@media screen and (max-width: 480px){
  .mat-table .mat-cell,.mat-table .mat-header-cell{
    white-space:pre-wrap !important
  }
}

.mat-raised-button{
  padding:0 8px !important
}

.px-6{
  padding-left:.5rem !important;
  padding-right:.5rem !important;
  padding-bottom:.5rem !important;
  padding-top:.5rem !important
}

.mat-expansion-panel-header{
  padding:5px !important;
  height:fit-content !important
}

.mat-expansion-panel{
  margin-top:0px !important;
  margin-bottom:0px !important
}

.add-new{
  margin-top:0rem !important;
  margin-bottom:1.25rem !important
}

.resume-review-comments .mat-dialog-container{
  padding:25px !important
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
  background-color:var(--obs-secondary) !important
}

.mat-select-value{
  height:16px !important
}

.no-padding-dialog .mat-list-item-content{
  padding:0px !important
}

.no-padding-bottom .mat-form-field-wrapper{
  padding-bottom:0px !important
}

.no-padding-bottom .mat-form-field-underline{
  bottom:0px !important
}

.page-title{
  color:#b71c1c;
  font-size:1.75rem;
  margin-bottom:.5rem;
  margin-top:.5rem;
  font-weight:500;
  line-height:1.2
}

.search-results{
  margin:0 1em 1em 1em;
  padding:1em;
  width:90%
}

.search-input{
  margin:1em;
  padding:1em;
  width:75%
}

.add-new-skill-dialog .mat-dialog-container{
  padding-bottom:10px !important
}

.mat-slide-toggle-label{
  margin-left:15px
}

.mat-grid-tile .mat-grid-tile-content{
  align-items:flex-start !important
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color:var(--obs-primary)
}

.location-dialog{
  width:unset !important;
  max-width:unset !important;
  max-height:unset !important
}

.location-dialog .mat-dialog-container{
  min-height:90vh !important;
  max-height:90vh !important;
  min-width:60vw !important;
  max-width:60vw !important
}